import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { activeChannelAtom } from '../../../atoms/activeChannelAtom';
import { messageAtom } from '../../../atoms/messageAtom';
import ChatMessage from './ChatMessage';
import ChatSystemMessage from './ChatSystemMessage';
import { Box, Flex } from '@chakra-ui/react';
import useIsMobile from '../../../hooks/useIsMobile';
import { usePlayerField } from '../../../hooks/hooks';
import { FaArrowCircleDown } from 'react-icons/fa';
import badwords from 'bad-words';
import {
	IChatMessage,
	ISystemMessage,
	IWhisperMessage,
} from '../../../../../game-server/src/modules/chat/Chat.interface';

const unfilterWords = ['bestial', 'god'];

const ChatMessageList = () => {
	const filter = new badwords();
	filter.removeWords(...unfilterWords);
	const channels = useRecoilValue(messageAtom);
	const activeChannel = useRecoilValue(activeChannelAtom);
	const container = useRef<HTMLDivElement>(null);
	const settings = usePlayerField('settings');
	const isMobile = useIsMobile();
	const [stickToBottom, setStickToBottom] = useState(true);

	const fontSize = settings.chat.fontSize;

	const channelKeys = Object.keys(channels);

	const activeChannelMessages = channels[channelKeys[activeChannel]];

	/** Check if the container should be scrolled on new message */
	useEffect(() => {
		checkScroll();
	}, [activeChannelMessages]);

	/** When changing tabs, always scroll to bottom */
	useEffect(() => {
		scrollToBottom();
	}, [activeChannel]);

	if (!activeChannelMessages)
		return (
			<Flex className='chat-message-list' justifyContent='center' alignItems='center'>
				Select a channel at the {isMobile ? 'top' : 'left'}.
			</Flex>
		);

	const activeChannelSettings = activeChannelMessages.length > 0 ? activeChannelMessages[0] : undefined;

	function checkScroll() {
		if (!stickToBottom) return;
		scrollToBottom();
	}

	function scrollToBottom() {
		if (!container.current) return;
		const { scrollHeight } = container.current as HTMLDivElement;
		container.current.scrollTo(0, scrollHeight);
	}

	function handleScroll() {
		if (!container.current) return;
		const { scrollHeight, scrollTop, clientHeight } = container.current;
		// <= since some browsers allow to scroll past the bottom
		// 50 gives about 3 messages of buffer with normal font size
		if (scrollHeight - scrollTop <= clientHeight + 50) {
			setStickToBottom(true);
		} else {
			setStickToBottom(false);
		}
	}

	// TODO: Make it smarter, right now it relies heavily on the basic bad-words filter
	// and cannot handle more complex cases, such as spaces between characters lol
	function filterProfanityChat(message: IChatMessage | IWhisperMessage) {
		if (!message.message) return message;
		if (!settings.chat.profanityFilter) return message;
		const copiedMessage = { ...message };
		copiedMessage.message = filterProfanity(copiedMessage.message);
		return copiedMessage;
	}
	function filterProfanitySystem(message: ISystemMessage): ISystemMessage {
		if (!message.message) return message;
		if (!settings.chat.profanityFilter) return message;
		const copiedMessage = { ...message };
		copiedMessage.message = filterProfanity(copiedMessage.message);
		return copiedMessage;
	}
	function filterProfanity(message: string): string {
		if (!message) return message;
		if (!settings.chat.profanityFilter) return message;
		const words = message.split(/(\|{.*?}\|)/g);
		const filteredWords = words.map((word) => {
			return cleanWord(word);
		});
		const filteredContent = filteredWords.join(' ');
		return filteredContent;
	}
	function cleanWord(word: string): string {
		if (filter.isProfane(word)) {
			return filter.clean(word);
		}
		// More robust check, regex check for profanity by cleaning up the word
		// Clean up word by removing all non-alphanumeric characters and repeating characters
		const cleanedWord = word.replace(/[^a-zA-Z0-9]/g, '').replace(/(.)\1+/g, '$1');
		if (filter.isProfane(cleanedWord)) {
			return filter.clean(cleanedWord);
		}
		return word;
	}

	return (
		<Box
			overflowY='auto'
			position='relative'
			backgroundColor='rgba(0, 0, 0, 0.404)'
			gridRow={channelKeys[activeChannel] === 'Activity' ? (isMobile ? '2 / -1' : '1 / 3') : undefined}
			className='chat-message-list'
		>
			<Box
				ref={container}
				overflowY='auto'
				paddingLeft='5px'
				fontSize={fontSize}
				lineHeight='1.3'
				onScroll={handleScroll}
				height='100%'
			>
				{activeChannelMessages.map((message) => {
					if ('type' in message && message.type === 'settings') {
						return null;
					}
					if ('type' in message && message.type === 'system-message') {
						return (
							<ChatSystemMessage
								key={
									'chat-system-message-' +
									message.createdAt +
									message.message +
									message.localIncrementingId
								}
								message={filterProfanitySystem(message)}
							/>
						);
					}
					return (
						<ChatMessage
							message={filterProfanityChat(message)}
							key={message.messageId}
							settings={activeChannelSettings}
						/>
					);
				})}
			</Box>
			{!stickToBottom && (
				<Flex
					position='absolute'
					bottom='5px'
					right='15px'
					cursor='pointer'
					onClick={scrollToBottom}
					width='20px'
					height='20px'
					alignItems='center'
					justifyContent='center'
					color='gray.400'
				>
					<FaArrowCircleDown />
				</Flex>
			)}
		</Box>
	);
};

export default ChatMessageList;
