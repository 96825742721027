import React from 'react';
import Tooltip from '../../layout/UI/Tooltip';
import { formatNumber } from '../../../helper/StringFormatter';
import CombatStats from './CombatStats';
import { usePlayerField } from '../../../hooks/hooks';
import { ICombatStatsData } from '../../../../../game-server/src/modules/combat/combatstats.interface';
import {
	AGILITY_BASE,
	AGILITY_BASE_MULT,
	AGILITY_MINIMUM,
	AGILITY_MINIMUM_FINAL_MULT,
	AGILITY_SCALING,
} from '../../../utils/constantsCollection';

export default function CombatPlayerStats() {
	const playerStats = usePlayerField('combatStats');
	const enchantments = usePlayerField('enchantments');

	const protection = playerStats.armor.protection;
	const resistance = playerStats.armor.resistance;
	const masteryDefense = playerStats.masteryDefense ?? 0;
	const masteryConstitution = playerStats.masteryConstitution ?? 0;
	const agility = playerStats.armor.agility;
	const stamina = playerStats.armor.stamina;
	const staminaBonus = Math.round((1 + stamina / 120) * 100);
	const protectionPercent = Math.round((300 / Math.max(100, 300 + protection + masteryDefense) - 1) * 100);
	const resistancePercent = Math.round((240 / Math.max(40, 200 + resistance + masteryConstitution) - 1) * 100);
	const agilityPercent =
		Math.max(
			AGILITY_MINIMUM_FINAL_MULT,
			AGILITY_BASE_MULT +
				Math.max(AGILITY_MINIMUM, agility) /
					(AGILITY_BASE + AGILITY_SCALING * Math.max(AGILITY_MINIMUM, agility))
		) * 100;
	const maxHits = getMaxHit(playerStats);
	const accuracy = Object.entries(playerStats.offensiveAccuracyAffinityRating).map(
		([affinityName, affinityValue]) => {
			return { affinityName, affinityValue };
		}
	);
	accuracy.sort((a, b) => b.affinityValue - a.affinityValue);

	return (
		<div className='combat-stats-wrapper'>
			<div className='combat-stats'>
				<div className='combat-stat-group-main idlescape-frame-panel'>
					<div className='combat-stat-health'>
						<img
							src='/images/combat/constitution_icon.png'
							className='combat-stat-health-icon'
							alt='Max Health'
						/>
						<div className='combat-stat-health-amount red-text'>{playerStats.maxHealth}</div>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Protection</div>
						<div className='combat-stat-value'>{playerStats.armor.protection.toFixed(0)}</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								<p>
									Protection decreases physical damage taken. You currently take{' '}
									{formatNumber(protectionPercent)}% physical damage. Gets a slight boost from Defense
									mastery.
								</p>
							</div>
						</Tooltip>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Resistance</div>
						<div className='combat-stat-value'>{playerStats.armor.resistance.toFixed(0)}</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								<p>
									Resistance decreases magical and elemental damage taken. You currently take{' '}
									{formatNumber(resistancePercent)}% magical and elemental damage. Gets a slight boost
									from Constitution mastery.
								</p>
							</div>
						</Tooltip>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Agility</div>
						<div className='combat-stat-value'>{playerStats.armor.agility.toFixed(0)}</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								<p>
									Agility increases your ability to dodge incoming attacks. Your dodge effectiveness
									from your current agility is {formatNumber(agilityPercent)}% There are diminishing
									returns after 100 agility.
								</p>
							</div>
						</Tooltip>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Stamina</div>
						<div className='combat-stat-value'>{playerStats.armor.stamina.toFixed(0)}</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								<p>
									Stamina increases your max health. Your current max HP is{' '}
									{formatNumber(staminaBonus)}% its base. Scales with Constitution, Constitution
									Mastery, and Stamina.
								</p>
							</div>
						</Tooltip>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Strength</div>
						<div className='combat-stat-value'>{playerStats.weapon.strength.toFixed(0)}</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								<p>
									Strength increases your melee damage. DMG Range: {maxHits.melee.min} -{' '}
									{maxHits.melee.max}. Gets a slight boost from Strength mastery.
								</p>
							</div>
						</Tooltip>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Dexterity</div>
						<div className='combat-stat-value'>{playerStats.weapon.dexterity.toFixed(0)}</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								<p>
									Dexterity increases your range damage. DMG Range: {maxHits.range.min} -{' '}
									{maxHits.range.max}. Gets a slight boost from Range mastery.
								</p>
							</div>
						</Tooltip>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Intellect</div>
						<div className='combat-stat-value'>{playerStats.weapon.intellect.toFixed(0)}</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								<p>
									Intellect increases your magic damage. DMG Range: {maxHits.magic.min} -{' '}
									{maxHits.magic.max}. Gets a slight boost from Magic mastery.
								</p>
							</div>
						</Tooltip>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Accuracy</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								{accuracy.map((affinity) => {
									return (
										<div
											className='combat-stat'
											key={'CombatStats-OffAcc-' + affinity.affinityName}
										>
											<div className='combat-stat-sub-name'>{affinity.affinityName}</div>
											<div
												className={`combat-stat-value ${
													affinity.affinityValue >= 0 ? 'green-text' : 'red-text'
												}`}
											>
												{formatNumber(affinity.affinityValue)}
											</div>
										</div>
									);
								})}
							</div>
						</Tooltip>
					</div>
				</div>
				<CombatStats enchants={enchantments} stats={playerStats} />
			</div>
		</div>
	);
}

export function getMaxHit(playerStats: ICombatStatsData) {
	const maxHits = { melee: { min: 0, max: 1 }, range: { min: 0, max: 1 }, magic: { min: 0, max: 1 } };

	const str = playerStats.strength * 2 + (playerStats.masteryStrength ?? 0) + playerStats.weapon.strength * 2;
	const mgc = playerStats.magic * 2 + (playerStats.masteryMagic ?? 0) + playerStats.weapon.intellect * 2;
	const rng = playerStats.range * 2 + (playerStats.masteryRange ?? 0) + playerStats.weapon.dexterity * 2;
	const minHitMult = playerStats.hitMults.minimum;
	const maxHitMult = playerStats.hitMults.maximum;
	if (maxHitMult * str >= 0 && minHitMult * str >= 0) {
		maxHits.melee.min = minHitMult * str;
		maxHits.melee.max = maxHitMult * str;
	}

	if (maxHitMult * mgc >= 0 && minHitMult * mgc >= 0) {
		maxHits.magic.min = minHitMult * mgc;
		maxHits.magic.max = maxHitMult * mgc;
	}

	if (maxHitMult * rng >= 0 && minHitMult * rng >= 0) {
		maxHits.range.min = minHitMult * rng;
		maxHits.range.max = maxHitMult * rng;
	}

	// Floor all the values
	maxHits.melee.min = Math.floor(maxHits.melee.min);
	maxHits.melee.max = Math.floor(maxHits.melee.max);
	maxHits.magic.min = Math.floor(maxHits.magic.min);
	maxHits.magic.max = Math.floor(maxHits.magic.max);
	maxHits.range.min = Math.floor(maxHits.range.min);
	maxHits.range.max = Math.floor(maxHits.range.max);

	return maxHits;
}
