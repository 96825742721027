import { socket } from '../../../../services/socket.service';
import { itemList } from '../../../../utils/itemList';
import { getTimeString, getMaxItemsCanCraft } from '../../../../helper/helperFunctions';
import Tooltip from '../../../layout/UI/Tooltip';
import './ScrollcraftingRecipe.css';
import { EnchantmentData } from '../../EnchantmentData';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { CraftingAugmentingData } from '../../CraftingAugmenting/CraftingAugmentingData';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import React, { useEffect, useRef } from 'react';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { IProgressBarRef, ProgressBar } from '../../../layout/UI/ProgressBar';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import { MINIMUM_ACTION_TIME_IN_SECONDS } from '../../../../utils/constantsCollection';
import { tabs } from '../../../../helper/NavigationTabsList';
import { Box } from '@chakra-ui/react';
import ActionQueueButton from '../../ActionQueue/ActionQueueButton';

export default function ScrollcraftingRecipe(props: { scroll: IItemData }) {
	const progressBarRef = useRef<IProgressBarRef>(null);

	const stockpile = usePlayerField('stockpile');
	const actionQueue = usePlayerField('actionQueue');
	const skills = usePlayerField('skills');
	const enchantingLevel = skills.enchanting.level;
	const enchantingMasteryLevel = skills.enchanting.masteryLevel;
	const settings = usePlayerField('settings');
	const highPerformance = settings.miscellaneous.highPerformance;
	const running = actionQueue?.options?.scrollcraftingId === props.scroll.id;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, 'enchanting');
	const scholarStrength = usePlayerEnchantmentStrength(enchantmentsIds.scholar, 'enchanting');
	const wealthStrength = usePlayerEnchantmentStrength(enchantmentsIds.wealth, 'enchanting');

	const overallTime = getTime();

	// Change image if wealth
	let scrollImage = <img className='scrollcrafting-image' src={props.scroll.itemImage} alt={props.scroll.name} />;
	if (wealthStrength) {
		const gold = Math.floor((props.scroll.value ?? 0) * (1 + wealthStrength));
		scrollImage = (
			<>
				<img className='scrollcrafting-image' src={itemList[itemsIds.gold].itemImage} alt='Gold coins' />
				<Box position='absolute' bottom='10px' width='60px' textAlign='center' fontSize='xl'>
					{gold.toLocaleString('en-us')}
				</Box>
			</>
		);
	}

	// Change image and experience if scholar
	let experience = props.scroll.experience ?? 0;
	if (scholarStrength && !wealthStrength) {
		experience *= 1 + scholarStrength;
		experience = Math.floor(experience);
		scrollImage = <img className='scrollcrafting-image' src='/images/total_level.png' alt='experience' />;
	}

	useEffect(() => {
		socket.on('animation:start', startAnimation);

		return () => {
			socket.off('animation:start');
		};
	}, []);

	useEffect(() => {
		if (!running) {
			progressBarRef.current?.stopAnimation();
		} else {
			const expectedEndTime = actionQueue?.info?.completionTime;
			let newLength = 0;
			if (expectedEndTime) {
				newLength = expectedEndTime - new Date().getTime();
			}
			progressBarRef.current?.startAnimation(newLength);
		}
	}, [running, actionQueue]);

	function startWorking() {
		if (running) {
			socket.emit('action:stop');
		} else {
			socket.emit('action:start', {
				action: 'enchanting',
				location: locationsIds.the_college_of_scrollcrafting,
				options: { scrollcraftingId: props.scroll.id },
			});
		}
	}

	function queueButton() {
		return (
			<ActionQueueButton
				action='enchanting'
				location={locationsIds.the_college_of_scrollcrafting}
				options={{ scrollcraftingId: props.scroll.id }}
			/>
		);
	}

	function startAnimation(data: { action: string; location: number; length: number; resource?: number }) {
		if (data.resource === props.scroll.id) {
			progressBarRef.current?.startAnimation(data.length * 1000);
		}
	}

	function getTime() {
		let time = (props.scroll.time ?? 5000) / 1000;
		time /= 1 + enchantingMasteryLevel * 0.05;
		time /= 1 + hasteStrength;
		return Math.max(MINIMUM_ACTION_TIME_IN_SECONDS, time);
	}

	// calculate required resource list
	const standardResources: React.ReactElement[] = [];
	const dynamicResources: React.ReactElement[] = [];
	let canCraft = true;

	//level
	if (props.scroll.level) {
		standardResources.push(
			<div className='resource-node-time-tooltip'>
				<img src={tabs['enchanting'].icon} alt='level' className='icon16' />
				<span>{props.scroll.level}</span>
				<Tooltip>
					<span className='resource-tooltip-text'>Level Required</span>
				</Tooltip>
			</div>
		);
	}

	//time
	if (props.scroll.time) {
		standardResources.push(
			<div className='resource-node-time-tooltip'>
				<img src='/images/clock.png' alt='time' className='icon16' />
				<span>{overallTime.toFixed(2)}s</span>
				<Tooltip>
					<span className='resource-tooltip-text'>Time Required</span>
				</Tooltip>
			</div>
		);
	}

	//other resources
	const scrollcraftingRecipe = CraftingAugmentingData.getScrollcraftingByID(props.scroll.id);
	if (!scrollcraftingRecipe) return null;
	for (const resource in scrollcraftingRecipe) {
		const requiredResourceAmount = scrollcraftingRecipe[resource];

		const availableItem = stockpile.find((obj) => {
			return obj.itemID == itemList[resource]?.id;
		});
		const enoughResources = (availableItem?.stackSize ?? 0) >= requiredResourceAmount;
		canCraft &&= enoughResources;

		const isScroll = itemList[resource]?.id === itemsIds.scroll;
		if (!itemList[resource]) return null;
		const tooltip = (
			<div className='resource-node-time-tooltip' data-itemid={resource}>
				<img
					src={itemList[resource]?.itemImage}
					alt={itemList[resource]?.name}
					className={isScroll ? 'icon16' : 'icon24'}
				/>
				<span className={enoughResources ? '' : 'red-text'}>{requiredResourceAmount}</span>
				<Tooltip>
					<span className='resource-tooltip-text'>
						Resource required:
						<br />
						{itemList[resource]?.name}
					</span>
				</Tooltip>
			</div>
		);

		if (isScroll) standardResources.push(tooltip);
		else dynamicResources.push(tooltip);
	}

	let categories = '';
	if (props.scroll.categories) {
		categories = `Applicable to: ${props.scroll.categories.join(', ')}.`;
	}

	let totalsBar;
	if (actionQueue?.options?.scrollcraftingId === props.scroll.id) {
		const numScrolls = getMaxItemsCanCraft(stockpile, scrollcraftingRecipe);
		const timeString = getTimeString(numScrolls * overallTime);

		totalsBar = (
			<div className='scrollcrafting-totals-bar'>
				Will stop crafting scrolls in <span className='key-data'>{timeString}</span>
				after creating <span className='key-data'>{numScrolls}</span> scrolls.
			</div>
		);
	}

	return (
		<div className='scrollcrafting-container' data-itemid={props.scroll.id}>
			{scrollImage}

			<Box
				className='scrollcrafting-title'
				textDecoration={(wealthStrength || scholarStrength) && 'line-through'}
			>
				{props.scroll.name}
				<Tooltip>
					<p>
						{props.scroll.enchantmentID &&
							'At Enchantment Strength 1: ' + EnchantmentData.getTooltip(props.scroll.enchantmentID, 1)}
					</p>
					<p>Applicable to: {(props.scroll.categories ?? []).join(', ')}.</p>
					<p>{experience.toLocaleString('en-us')} Enchanting experience</p>
				</Tooltip>
			</Box>

			<div className='scrollcrafting-button'>
				<button disabled={!canCraft} onClick={startWorking}>
					{running ? 'Stop' : 'Craft'}
				</button>
				{queueButton()}
			</div>

			<div className='scrollcrafting-resources'>
				<div className='scrollcrafting-standard-resources'>
					{standardResources.map((resource, index) => (
						<div key={'standard-resource-' + index} className='scrollcrafting-property'>
							{resource}
						</div>
					))}
				</div>
				<div className='scrollcrafting-dynamic-resources'>
					{dynamicResources.map((resource, index) => (
						<div key={'dynamic-resource-' + index} className='scrollcrafting-property'>
							{resource}
						</div>
					))}
				</div>
			</div>

			{!highPerformance && (
				<ProgressBar ref={progressBarRef} value={0} max={100} theme='default' gridArea='bar' height='100%' />
			)}

			{totalsBar}

			{(props.scroll.level ?? 0) > enchantingLevel && (
				<>
					<div
						className='scrollcrafting-locked-cover'
						data-tip
						data-for={props.scroll.name + 'resource_as_row2'}
					>
						<p>
							Requires {props.scroll.level}
							<img src={tabs['enchanting'].icon} className='icon50' alt='' />
						</p>
					</div>
					<Tooltip>
						<p>{props.scroll.extraTooltipInfo}</p>
						<p>{categories}</p>
						<p>{props.scroll.experience} Enchanting experience</p>
					</Tooltip>
				</>
			)}
		</div>
	);
}
