import { Box, Flex, Grid, Heading, Image, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeFrameBox, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useEffect, useRef, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerAffixStrength, usePlayerEnchantmentStrength, usePlayerField } from '../../../../../hooks/hooks';
import { socket } from '../../../../../services/socket.service';
import {
	getScrapExperience,
	getScrapSuccessChance,
	scrappingTime,
	getItemTier,
	getDustIDFromItemTier,
	itemCanBeScrapped,
} from '../../../../../utils/augmentingFunctions';
import { AFFIX_GEAR_SCRAP_PER_RARITY } from '../../../../../utils/constantsCollection';
import { getTimeString } from '../../../../../helper/helperFunctions';
import { itemList } from '../../../../../utils/itemList';
import { enchantmentsIds } from '../../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { locationsIds } from '../../../../../utils/lookup-dictionaries/lookupLocationList';
import { IProgressBarRef, ProgressBar } from '../../../../layout/UI/ProgressBar';
import ActionQueueButton from '../../../ActionQueue/ActionQueueButton';
import { CraftingAugmentingData } from '../../../CraftingAugmenting/CraftingAugmentingData';
import ResourceList from '../../../ResourceList';
import { Item } from '../../../Inventory/Item';
import ResourceCost from '../../ResourceCost';
import { augmentingLootList } from '../../../../../utils/augmentingLootList';
import { affixList } from '../../../../../utils/affixList';

export default function ScrappingItemInfo(props: { previewItem: IItem | null }) {
	const scrapQueue = usePlayerField('scrapQueue');
	const settings = usePlayerField('settings');
	const skills = usePlayerField('skills');
	const stockpile = usePlayerField('stockpile');
	const enchantingLevel = skills.enchanting.level;
	const masteryEnchantingLevel = skills.enchanting.masteryLevel;
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');
	const enchantingToolBoost = skillEquipmentStats.enchanting;
	const effectiveEnchantingLevel = enchantingLevel + masteryEnchantingLevel + enchantingToolBoost;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, 'enchanting');
	const dusterStrength = usePlayerEnchantmentStrength(enchantmentsIds.duster, 'enchanting');
	const scrapperStrength = usePlayerEnchantmentStrength(enchantmentsIds.scrapper, 'enchanting');
	const researchReadyStrength = usePlayerEnchantmentStrength(enchantmentsIds.research_ready, 'enchanting');
	const madScientistStrength = usePlayerEnchantmentStrength(enchantmentsIds.mad_scientist, 'enchanting');
	const bonusChance = dusterStrength + researchReadyStrength - scrapperStrength - madScientistStrength;
	const chancesStrength = usePlayerEnchantmentStrength(enchantmentsIds.chances, 'enchanting');
	const chancesAffixStrength = usePlayerAffixStrength('enchanting.augmenting_transform_chance_bonus');

	const displayedItem = props.previewItem ?? (scrapQueue.length !== 0 ? scrapQueue[0] : undefined);
	const displayedItemData = displayedItem ? itemList[displayedItem.itemID] : undefined;

	const highPerformance = settings.miscellaneous.highPerformance;
	const actionQueue = usePlayerField('actionQueue');
	const active = actionQueue?.location === locationsIds.the_institute_of_research;
	const [animating, setAnimating] = useState('');

	const progressBarRef = useRef<IProgressBarRef>(null);

	const rarity = displayedItemData?.rarity ?? 'common';
	const itemTier = displayedItemData ? getItemTier(displayedItemData) : undefined;
	let hue = 0;
	switch (rarity) {
		case 'uncommon':
			hue = 50;
			break;
		case 'rare':
			hue = 180;
			break;
		case 'epic':
			hue = 8;
			break;
		case 'legendary':
			hue = 310;
			break;
		default:
			hue = 180;
			break;
	}

	const found = scrapQueue.findIndex((item) => itemCanBeScrapped(item, stockpile, itemList));
	const enoughMaterials = found !== -1;

	let scrapCost: React.ReactNode = null;
	if (displayedItem) {
		const scrapData = CraftingAugmentingData.getScrappingByID(displayedItem.itemID);
		if (scrapData) {
			const itemCost = Object.entries(scrapData).map(([itemId, itemCost]) => {
				return { resource: itemList[Number(itemId)], count: itemCost };
			});
			scrapCost = <ResourceCost resourceCosts={itemCost} variant='cooking' imageSize={60} />;
		}
	}

	const scrapFail = displayedItem ? augmentingLootList[displayedItem.itemID]?.scrappingFail : undefined;
	const scrapSuccess = displayedItem ? augmentingLootList[displayedItem.itemID]?.scrappingSuccess : undefined;
	const transforms = displayedItem
		? augmentingLootList[displayedItem.itemID]?.transforms?.filter((t) => !t.augmentingTransform)
		: undefined;

	useEffect(() => {
		socket.on('augmenting:response', (data) => {
			if (data.response) {
				setAnimating(data.response);
				setTimeout(() => {
					setAnimating('');
				}, 1000);
			}
		});
		socket.on('animation:start', onStartAnimation);

		return () => {
			socket.off('augmenting:response');
			// Requires listener as it fires after componentDidUpdate, can remove after all other actions are refactored
			socket.off('animation:start');
		};
	}, [progressBarRef]);

	useEffect(() => {
		if (!active) {
			progressBarRef.current?.stopAnimation();
		}
	}, [actionQueue]);

	function onStartAnimation(data: { action: string; location: number; length: number; resource?: number }) {
		if (
			'Action-Enchanting' === data.action &&
			data.location === locationsIds.the_institute_of_research &&
			data?.resource === undefined
		) {
			progressBarRef.current?.startAnimation(data.length * 1000);
		}
	}

	function startStopAugmenting() {
		if (active) {
			socket.emit('action:stop');
		} else {
			socket.emit('action:start', { action: 'enchanting', location: locationsIds.the_institute_of_research });
		}
	}

	function queueButton() {
		return (
			<ActionQueueButton action='enchanting' location={locationsIds.the_institute_of_research} variant='orange' />
		);
	}

	function canStartAugmenting() {
		return scrapQueue.length > 0 && enoughMaterials;
	}

	function getDustItem() {
		const dustID = getDustIDFromItemTier(itemTier);
		const fakeItemArray: { id: number; chance: number; minAmount?: number; maxAmount?: number }[] = [
			{
				id: dustID,
				chance: 1,
				minAmount: 1,
				maxAmount: 1,
			},
		];
		if (scrapSuccess) {
			const { itemID: id, chance, minimum: minAmount, maximum: maxAmount } = scrapSuccess;
			fakeItemArray.push({ id, chance: chance ?? 1, minAmount, maxAmount });
		}
		return <ResourceList resources={fakeItemArray} />;
	}

	function getScrapItem() {
		const scrapID = AFFIX_GEAR_SCRAP_PER_RARITY[rarity];
		//const fakeItemArray = [<FakeItem item={{ itemID: scrapID }} amountString={getItemRange()} />];
		const fakeItemArray: { id: number; chance: number; minAmount?: number; maxAmount?: number }[] = [
			{
				id: scrapID,
				chance: 1,
				minAmount: 1,
				maxAmount: 1,
			},
		];
		if (scrapFail) {
			const { itemID: id, chance, minimum: minAmount, maximum: maxAmount } = scrapFail;
			fakeItemArray.push({ id, chance: chance ?? 1, minAmount, maxAmount });
		}
		return <ResourceList resources={fakeItemArray} />;
	}

	function getTransformItems() {
		if (!transforms || transforms.length === 0) return null;
		// No chance, so "???" is displayed
		const fakeItemArray = transforms.map((transform) => ({
			id: transform.newItemID,
			chance: transform.chance * (1 + chancesStrength + chancesAffixStrength),
		}));
		return (
			<>
				<IdlescapeWrappingTooltip
					content={`Has a chance to tranform into the item${
						transforms.length > 1 ? 's' : ''
					} below on every successful research action.`}
				>
					<Text>Transforms</Text>
				</IdlescapeWrappingTooltip>
				<Flex justifyContent='center'>
					<ResourceList resources={fakeItemArray} />
				</Flex>
			</>
		);
	}

	return (
		<IdlescapeContainer as={Flex} flexDirection='column' alignItems='center' width='100%' order={-1}>
			<Heading size={'md'} textAlign='center'>
				{displayedItem ? displayedItem.name : 'Item Details'}
				{props.previewItem !== null ? ' (Preview)' : ' '}
			</Heading>
			{displayedItem !== undefined && displayedItemData !== undefined ? (
				<>
					<Flex alignItems='center' justifyContent='space-evenly' gap='5px' marginBottom='5px' width='100%'>
						<Flex gap='5px' justifyContent='center' flexWrap='wrap'>
							{scrapCost}
						</Flex>
						<Flex marginBottom='20px' alignItems='center' position='relative' zIndex='1'>
							<Item
								item={displayedItem}
								gridRow='span 2'
								animation={
									props.previewItem === null && active && animating !== ''
										? `augmenting-${animating} 1000ms linear`
										: ''
								}
							/>
						</Flex>
					</Flex>
					<Box position='relative'>
						{!highPerformance && props.previewItem === null && (
							<Box
								width='200px'
								height='200px'
								position='absolute'
								top='-60px'
								left='-38px'
								filter={`hue-rotate(${hue}deg) saturate(3)`}
							>
								<ProgressBar ref={progressBarRef} value={0} max={100} theme='scrapping' />
							</Box>
						)}
						<IdlescapeButton
							onClick={startStopAugmenting}
							variant={active ? 'red' : canStartAugmenting() ? 'purple' : 'disabled'}
							className={canStartAugmenting() && !active ? 'enchanting-glowing-button' : ''}
							margin='12px'
						>
							{active ? 'Stop' : 'Start'}
						</IdlescapeButton>
						{queueButton()}
					</Box>
					<Flex justifyContent='center' width='100%' alignItems='center' gap='5px' marginBottom='10px'>
						{getDustItem()}
						On fail <FaArrowRight />
						<Flex flexDirection='row' alignItems='center'>
							{displayedItemData.researchesIntoDust ? getDustItem() : getScrapItem()}
						</Flex>
					</Flex>
					{getTransformItems()}
				</>
			) : (
				<Text fontSize='lg' textAlign='center'>
					No Item in the queue
				</Text>
			)}
			<Flex alignItems='center' flexDirection='column' minWidth='200px'>
				{displayedItem !== undefined && displayedItemData !== undefined && (
					<IdlescapeFrameBox
						as={Grid}
						gridTemplateColumns='repeat(3, 1fr)'
						alignItems='center'
						justifyItems='center'
						width='100%'
						padding='8px 15px'
						_hover={{}}
					>
						<Image src='/images/clock.png' alt='Duration' width='25px' height='25px' />
						<IdlescapeWrappingTooltip content='Chance for a successful attempt. On success affix dust is created, otherwise a gear scrap is created and the item is destroyed.'>
							<Image src='/images/fishing/chance_icon.png' alt='Chance' width='25px' height='25px' />
						</IdlescapeWrappingTooltip>
						<IdlescapeWrappingTooltip content='Experience per research attempt.'>
							<Image src='/images/total_level.png' alt='Experience' width='25px' height='25px' />
						</IdlescapeWrappingTooltip>
						<Text margin='2px' className='anchor-researching-time'>
							{getTimeString(scrappingTime(displayedItemData, effectiveEnchantingLevel, hasteStrength))}
						</Text>
						<Text margin='2px' className='anchor-researching-chance'>
							{getScrapSuccessChance(
								effectiveEnchantingLevel,
								displayedItem,
								displayedItemData,
								bonusChance
							).toLocaleString('en-us', { style: 'percent', minimumFractionDigits: 2 })}
						</Text>
						<Text margin='2px' className='anchor-researching-exp'>
							{getScrapExperience(displayedItemData)}
						</Text>
					</IdlescapeFrameBox>
				)}
			</Flex>
		</IdlescapeContainer>
	);
}
