import { Box, Img, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { drawerAtom } from '../../../atoms/drawerAtom';
import { tabs } from '../../../helper/NavigationTabsList';
import { NavDrawerPin } from '../NavDrawerPin';
import { useOutsideClick } from '@chakra-ui/react';
import { NavDrawerShowLevels } from '../NavDrawerShowLevels';
import { IdlescapeButton } from '@idlescape/ui';
import { BiLogOut } from 'react-icons/bi';
import { GiCharacter } from 'react-icons/gi';
import { VscFeedback } from 'react-icons/vsc';
import useNavbarPinned from '../../../hooks/useNavbarPinned';
import { useLocalStorage } from 'usehooks-ts';
import NavFeedback from '../NavFeedback';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../../actions/authActions';
import store from '../../../store';
import { TActiveTab, TSkillTab } from '../../../../../game-server/src/Atypes/Skills';
import SkillLevel from '../levels/SkillLevel';
import { useUnsavePlayerField } from '../../../hooks/hooks';
import { leagueList } from '../../../utils/leagueList';
import { ItemLog2 } from './ItemLog2';
import { openGameUrl } from '../../../GameUrls';
import { questList } from '../../../utils/questList';
import { completedButNotTurnedInQuests, unacceptedQuestCount } from '../../game/Achievements/QuestSummary';

export const Drawer = ({ loaded }: { loaded?: boolean }) => {
	const { navbarPinned } = useNavbarPinned();
	const [drawerOpen, setDrawerOpen] = useRecoilState(drawerAtom);
	const [showLevels] = useLocalStorage('navbar-showLevels', true);
	const [activeTab, setActiveTab] = useRecoilState(activeTabAtom);
	const ref = useRef(null);
	// usePlayerField is undefined at startup!!
	const leagueId = useUnsavePlayerField('league');
	const leagueData = leagueId ? leagueList[leagueId] : undefined;
	const navigate = useNavigate();
	const hideGathering = leagueData?.name === 'No Gather Ironman';

	// usePlayerField is undefined at startup!!
	const farmingSpacesOpen = useUnsavePlayerField('farmingSpacesOpen');
	const questIdsCompleted = useUnsavePlayerField('questsCompleted')?.map((quest) => quest.id);
	const questProgress = useUnsavePlayerField('questProgress');
	const skills = useUnsavePlayerField('skills');
	const subscription = useUnsavePlayerField('subscription');
	const isSubscribed = subscription?.active ?? false;
	const bonusActionQueueCount = useUnsavePlayerField('bonusActionQueueCount');

	// Feedback form
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		// Open drawer on startup if navbar is pinned
		if (navbarPinned && !drawerOpen) {
			setDrawerOpen(true);
		}
	}, []);

	useOutsideClick({
		ref,
		handler: (e: Event) => {
			const element = e.target as HTMLElement | null;
			if (
				element &&
				typeof element.className === 'string' &&
				(element.className?.includes('drawer-button') || element.className?.includes('header-league-icon'))
			) {
				return;
			}

			closeDrawer();
		},
	});

	function closeDrawer() {
		if (!navbarPinned) {
			setDrawerOpen(false);
		}
	}

	function logout() {
		logoutUser(store.dispatch);
		openGameUrl('landing');
	}

	function getQuestNotification() {
		if (!questIdsCompleted || !questProgress || !skills) {
			return null;
		}
		// This has to calculate every re-render, could be optimized

		let questPoints = 0;
		Object.values(questList).forEach((quest) => {
			if (questIdsCompleted.includes(quest.id)) {
				questPoints += quest.questRewards?.questPoints ?? 0;
			}
		});

		const unacceptedCount = unacceptedQuestCount(
			questIdsCompleted,
			questProgress,
			questPoints,
			skills,
			isSubscribed,
			bonusActionQueueCount ?? 0
		);
		// Get the count of quests that are complete but not turned in
		const completedButNotTurnedIn = completedButNotTurnedInQuests(questProgress, questIdsCompleted);
		return (
			<>
				{unacceptedCount > 0 && (
					<Box
						right='0'
						top='0'
						backgroundColor='red'
						color='white'
						fontSize='0.8rem'
						padding='0 5px'
						borderRadius='25%'
						display='flex'
						alignItems='center'
						justifyContent='center'
						textAlign='center'
					>
						{unacceptedCount}
					</Box>
				)}
				{completedButNotTurnedIn > 0 && (
					<Box
						right='0'
						top='0'
						backgroundColor='green'
						color='white'
						fontSize='0.8rem'
						padding='0 5px'
						borderRadius='25%'
						display='flex'
						alignItems='center'
						justifyContent='center'
						textAlign='center'
					>
						{completedButNotTurnedIn}
					</Box>
				)}
			</>
		);
	}

	function DrawerSkill({ skill }: { skill: TSkillTab }) {
		const skillData = tabs[skill];
		return (
			<Box
				cursor={'pointer'}
				_hover={{
					background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 100%)',
				}}
				marginTop={'5px'}
				height={'30px'}
				textAlign={'left'}
				display={'flex'}
				verticalAlign={'middle'}
				lineHeight={'30px'}
				fontSize={'16px'}
				padding={'0 10px'}
				background={
					activeTab === skill
						? 'linear-gradient(90deg, rgba(144, 205, 244, 0.25) 0, rgba(144, 205, 244, 0.05) 100%)'
						: 'transparent'
				}
				boxShadow={activeTab === skill ? '0 0 10px 0 rgba(144, 205, 244, 0.5)' : 'none'}
				/* boxShadow={activeTab === skill ? '0 0 10px 0 rgba(0, 0, 0, 0.7) inset' : 'none'} */
				onClick={() => {
					closeDrawer();
					setActiveTab(skill);
				}}
				className={`anchor-drawer-${skill}`}
			>
				<Img src={skillData.icon} width={'25px'} objectFit='contain'></Img>
				<Box marginLeft={'10px'}>{skillData.name}</Box>
				{Number(farmingSpacesOpen) > 0 && skill === 'farming' && (
					<Box
						right='0'
						top='0'
						backgroundColor='red'
						color='white'
						fontSize='0.8rem'
						padding='0 5px'
						borderRadius='25%'
						display='flex'
						alignItems='center'
						justifyContent='center'
						textAlign='center'
					>
						{farmingSpacesOpen}
					</Box>
				)}
				{showLevels && (
					<Box width={'40%'} marginLeft={'auto'}>
						<SkillLevel type='barNoIcon' skill={skill} />
					</Box>
				)}
			</Box>
		);
	}

	function DrawerItem({ item }: { item: TActiveTab }) {
		const tabData = tabs[item];
		return (
			<Box
				cursor={'pointer'}
				_hover={{
					background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 100%)',
				}}
				marginTop={'5px'}
				height={'30px'}
				textAlign={'left'}
				display={'flex'}
				verticalAlign={'middle'}
				lineHeight={'30px'}
				fontSize={'16px'}
				padding={'0 10px'}
				background={
					activeTab === item
						? 'linear-gradient(90deg, rgba(144, 205, 244, 0.25) 0, rgba(144, 205, 244, 0.05) 100%)'
						: 'transparent'
				}
				onClick={() => {
					closeDrawer();
					setActiveTab(item);
				}}
				className={`anchor-drawer-${item}`}
			>
				<Img src={tabData.icon} width={'25px'} objectFit='contain'></Img>
				<Box marginLeft={'10px'}>{tabData.name}</Box>
				{item === 'quests' && getQuestNotification()}
			</Box>
		);
	}

	function DrawerCustomItem({ text, icon, func }: { text: string; icon: string; func: () => void }) {
		return (
			<Box
				cursor={'pointer'}
				_hover={{
					background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 100%)',
				}}
				marginTop={'5px'}
				height={'30px'}
				textAlign={'left'}
				display={'flex'}
				verticalAlign={'middle'}
				lineHeight={'30px'}
				fontSize={'16px'}
				padding={'0 10px'}
				onClick={func}
				className={`anchor-drawer-${text.toLowerCase()}`}
			>
				<Img src={icon} width={'25px'}></Img>
				<Box marginLeft={'10px'}>{text}</Box>
			</Box>
		);
	}

	function DrawerHeader({ text }: { text: string }) {
		return (
			<Box textAlign={'left'} fontSize={'22px'} color={'gray'}>
				{text}
			</Box>
		);
	}

	return (
		<Box
			backgroundColor={'pink'}
			width={drawerOpen ? (showLevels ? '300px' : '175px') : '0'}
			overflowX={'hidden'}
			zIndex={1000}
			transition={'width 0.3s'}
			backgroundImage={
				'linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.1) 90%, rgba(56, 56, 56, 0.3) 100%), url(/images/ui/stone_ui_tile_dark_gray.png)'
			}
			filter={'drop-shadow(0 0 0.45rem black)'}
			textAlign={'center'}
			gridArea='navdrawer'
			ref={ref}
		>
			<Box position={'absolute'} top={'20px'} left={'10px'} display={'flex'} flexDir={'column'} gap={'10px'}>
				<NavDrawerPin></NavDrawerPin>
				<NavDrawerShowLevels></NavDrawerShowLevels>
			</Box>
			<Img height='100px' src='/images/landing/logo_2.0.png' width={'50%'} objectFit='contain'></Img>
			{loaded && <ItemLog2 />}
			<DrawerItem item='session-stats' />
			<DrawerItem item='action-queue' />
			{!hideGathering && (
				<>
					<DrawerHeader text='Gathering' />
					<DrawerSkill skill='mining' />
					<DrawerSkill skill='foraging' />
					<DrawerSkill skill='fishing' />
				</>
			)}

			<DrawerHeader text='Production' />
			<DrawerSkill skill='smithing' />
			<DrawerSkill skill='farming' />
			<DrawerSkill skill='cooking' />
			<DrawerSkill skill='crafting' />

			<DrawerHeader text='Magic' />
			<DrawerSkill skill='runecrafting' />
			<DrawerSkill skill='enchanting' />

			<DrawerHeader text='Combat' />
			<DrawerSkill skill='constitution' />
			<DrawerSkill skill='attack' />
			<DrawerSkill skill='defense' />
			<DrawerSkill skill='strength' />
			<DrawerSkill skill='magic' />
			<DrawerSkill skill='range' />

			<DrawerHeader text='Commerce' />
			{leagueData?.rules.marketplace && <DrawerItem item='marketplace' />}
			<DrawerItem item='general-shop' />
			{leagueData?.rules.eventShop && <DrawerItem item='event-shop' />}
			{leagueData?.rules.shrine && <DrawerItem item='shrine' />}
			<DrawerItem item='platinum-shop' />

			<DrawerHeader text='Miscellaneous' />
			{leagueData?.rules.achievements && <DrawerItem item='achievements' />}
			{leagueData?.rules.quests && <DrawerItem item='quests' />}
			{leagueData?.rules.talents && <DrawerItem item='talents' />}
			<DrawerItem item='settings' />
			{/* Allows userscripts to inject their own sidebar buttons, without them getting moved around */}
			<Box className='anchor-userscript-settings' />
			<DrawerCustomItem
				text='Rules'
				icon='/images/misc/notepad_icon.png'
				func={() => openGameUrl('rules', { navigate, openNew: true })}
			/>

			<DrawerHeader text='Community' />
			<DrawerCustomItem
				text='Discord'
				icon='/images/discord_logo.png'
				func={() => openGameUrl('discord', { navigate, openNew: true })}
			/>
			<DrawerCustomItem
				text='Reddit'
				icon='/images/reddit_logo.png'
				func={() => openGameUrl('reddit', { navigate, openNew: true })}
			/>
			<DrawerCustomItem
				text='Wiki'
				icon='/images/wiki_logo_v2.png'
				func={() => openGameUrl('wiki', { navigate, openNew: true })}
			/>

			<IdlescapeButton
				variant='green'
				width={'100%'}
				onClick={() => {
					onOpen();
					closeDrawer();
				}}
			>
				<VscFeedback /> <Text marginLeft={'5px'}>Send Feedback</Text>
			</IdlescapeButton>
			<NavFeedback open={isOpen} close={onClose} />
			<IdlescapeButton variant='blue' width={'100%'} onClick={() => openGameUrl('characters', { navigate })}>
				<GiCharacter /> <Text marginLeft={'5px'}>Change Character</Text>
			</IdlescapeButton>
			<IdlescapeButton variant='red' width={'100%'} onClick={() => logout()}>
				<BiLogOut /> <Text marginLeft={'5px'}>Log Out</Text>
			</IdlescapeButton>
		</Box>
	);
};
