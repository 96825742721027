import { Box, Grid, Img, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { IMarketListing } from '../../../../../game-server/src/repositories/MarketListing.repository';
import { shareMarketplaceListingSelector } from '../../../atoms/chatHistoryAtom';
import useIsMobile from '../../../hooks/useIsMobile';
import { itemImage, textAugment, textEnchant } from '../../../helper/itemHelperFunctions';
import { itemList } from '../../../utils/itemList';
import { SellMarketplaceDialogue } from '../../layout/dialogs/SellMarketplaceDialogue';
import ItemTooltip from '../Tooltips/ItemTooltip';

export default function MarketplaceMyAuctionsItem(props: { listing: IMarketListing }) {
	const item = props.listing.item;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const shareMarketplaceListing = useSetRecoilState(shareMarketplaceListingSelector);
	const itemDetails = itemList[item.itemID];
	const isMobile = useIsMobile();

	return (
		<>
			<Grid
				gridTemplateColumns={'30% 15% 20% 20% 15%'}
				gridTemplateRows={'50px'}
				onClick={onOpen}
				position={'relative'}
				className={'marketplace-table-row'}
				cursor='pointer'
				_hover={{ backgroundColor: 'rgba(104, 104, 104, 0.9) !important' }}
				alignItems={'center'}
			>
				<Box>
					{textAugment(item)}
					{textEnchant(item)}
				</Box>
				<Box position={'relative'} height={'50px'}>
					{itemImage(itemDetails)}
				</Box>
				<Box>{item.stackSize.toLocaleString('en-us')}</Box>
				<Box>{props.listing.price.toLocaleString('en-us')}</Box>
				<Box zIndex={1000} onClick={(e) => e.stopPropagation()}>
					<Img
						src='/images/chaticons/chat-icon-market-chat.png'
						className='share-marketplace-listing-button'
						onClick={() => shareMarketplaceListing(props.listing.listingId.toString())}
					></Img>
				</Box>
				{!isMobile && (
					<ItemTooltip item={item} forceDefaultPositions={true} listingDate={props.listing.listingDate} />
				)}
			</Grid>
			{isOpen && <SellMarketplaceDialogue listingProp={props.listing} onClose={onClose} />}
		</>
	);
}
