import React, { MouseEvent, useEffect, useRef } from 'react';
import './Quests.css';
import { IQuestData, IQuestProgress } from '../../../../../game-server/src/modules/quest/Quest.interface';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Image,
} from '@chakra-ui/react';
import { ProgressBar } from '../../layout/UI/ProgressBar';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { questAtom } from '../../../atoms/questAtom';
import { usePlayerField } from '../../../hooks/hooks';
import { checkRequirements } from './QuestFunctions';
import { AiOutlinePushpin } from 'react-icons/ai';
import { useReadLocalStorage } from 'usehooks-ts';
import { IQuestFilters } from './QuestList';
import { socket } from '../../../services/socket.service';
import { shareQuestSelector } from '../../../atoms/chatHistoryAtom';

interface QuestEntryProps {
	quest: IQuestData;
	questProgress: IQuestProgress[];
}

export default function QuestEntry({ quest, questProgress }: QuestEntryProps) {
	const [currentQuest, setCurrentQuest] = useRecoilState(questAtom);
	const questFilters = useReadLocalStorage<IQuestFilters>('questFilters');
	const skills = usePlayerField('skills');
	const settings = usePlayerField('settings');
	const questsCompleted = usePlayerField('questsCompleted');
	const shareQuest = useSetRecoilState(shareQuestSelector);
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);
	const metRequirements = checkRequirements(quest, skills, questIdsCompleted);

	const pinnedQuest = settings.quest.pin;

	const completed = questIdsCompleted.find((completedQuest) => completedQuest === quest.id);
	const inProgress = questProgress.filter((questProgress) => questProgress.id === quest.id);
	const isPinned = pinnedQuest === quest.id;

	const scrollToPinned = useRef(null);
	useEffect(() => {
		if (scrollToPinned.current && isPinned) {
			const divToScroll = scrollToPinned.current as HTMLElement;
			divToScroll.scrollIntoView();
		}
	}, [scrollToPinned]);

	function pinQuest(quest: number, e?: React.MouseEvent) {
		if (e) e.stopPropagation();
		socket.emit('settings:update', {
			value: settings.quest.pin === quest ? 0 : quest,
			path: 'quest.pin',
		});
	}

	let totalProgress = 0;
	let previousStepCompleted = inProgress.length > 0;
	const renderQuestSteps = quest.questSteps.map((step) => {
		let currentProgress =
			questProgress.find((questProgress) => questProgress.id === quest.id && step.stepID === questProgress.stepID)
				?.current ?? 0;
		if (questsCompleted.find((completed) => completed.id === quest.id)) {
			currentProgress = step.targetAmount;
		}
		const inProgress = questProgress.find(
			(questProgress) => questProgress.id === quest.id && step.stepID === questProgress.stepID
		);
		totalProgress += currentProgress / step.targetAmount;

		let stepImage: React.ReactElement | null = null;
		if (step.image) {
			stepImage = <Image width='10px' src={step.image.image} />;
		}
		let extraCSS = '';
		if (currentProgress === step.targetAmount) {
			extraCSS = 'quest-listing-completed';
		} else if (!metRequirements || !previousStepCompleted) {
			extraCSS = 'quest-listing-requirement-unmet';
		} else {
			extraCSS = 'quest-listing-in-progress-2';
		}
		if (currentQuest.quest === quest.id && currentQuest.step === step.stepID) {
			extraCSS += ' quest-listing-highlight';
		}
		if (currentProgress !== step.targetAmount) {
			previousStepCompleted = false;
		}

		return (
			<Flex
				position='relative'
				className={`quest-step ${extraCSS}`}
				key={`quest-step-${quest.id}-${step.stepID}`}
				cursor='pointer'
				padding='5px 15px'
				onClick={() => selectQuest({ quest: quest.id, step: step.stepID })}
				alignItems='center'
				justifyContent='space-between'
			>
				{inProgress && (
					<ProgressBar
						position='absolute'
						top='0'
						left='0'
						theme='quest-list'
						value={currentProgress}
						max={step.targetAmount}
						height='100%'
						width='100%'
					/>
				)}
				<Box position='relative'>
					{step.stepID}. {step.name}
					{stepImage}
				</Box>
			</Flex>
		);
	});

	if (quest.questSteps.length > 1) totalProgress /= quest.questSteps.length;

	function selectQuest(curQuest: { quest: number; step: number }) {
		setCurrentQuest(curQuest);
		if (
			questFilters?.pinSelected &&
			curQuest.quest !== settings.quest.pin &&
			inProgress.find((q) => q.id === curQuest.quest)
		) {
			pinQuest(curQuest.quest);
		}
	}

	function renderPin() {
		const pinned = quest.id === pinnedQuest;
		return (
			<Box
				position='relative'
				fontSize={20}
				marginLeft='5px'
				marginTop='4px'
				cursor='pointer'
				onClick={(e) => pinQuest(quest.id, e)}
				color={pinned ? 'white' : 'gray'}
				_hover={{ color: 'white' }}
			>
				<AiOutlinePushpin />
			</Box>
		);
	}

	function clickShare(e: MouseEvent) {
		if ((completed || inProgress.length > 0) && (e.ctrlKey || e.metaKey)) {
			shareQuest(quest.id.toString());
			e.stopPropagation();
			return true;
		}
		return false;
	}

	function renderQuest(quest: IQuestData) {
		let extraCSS = '';
		if (completed) {
			extraCSS = 'quest-listing-completed';
		} else if (!metRequirements) {
			extraCSS = 'quest-listing-requirement-unmet';
		} else if (totalProgress === 1) {
			extraCSS = ' quest-listing-complete';
		} else if (inProgress.length > 0) {
			extraCSS = 'quest-listing-in-progress';
		}
		if (currentQuest.quest === quest.id) {
			extraCSS += ' quest-listing-highlight';
		}

		if (quest.questSteps.length <= 1) {
			return (
				<Flex
					ref={scrollToPinned}
					position='relative'
					className={`quest-step ${extraCSS}`}
					fontSize='14px'
					onClick={(e) => {
						if (clickShare(e)) return;
						selectQuest({
							quest: quest.id,
							step: quest.questSteps.length ? quest.questSteps[0].stepID : 0,
						});
					}}
					padding='0 var(--chakra-space-4)'
				>
					{totalProgress > 0 && totalProgress < 1 && (
						<ProgressBar
							position='absolute'
							top='0'
							left='0'
							theme='quest-list'
							value={totalProgress}
							max={1}
							height='100%'
							width='100%'
						/>
					)}
					<Flex position='relative' alignItems='center' justifyContent='space-between' width='100%'>
						<Flex alignItems='center'>
							{quest.questIcon && <Image width='24px' height='24px' src={quest.questIcon} />}
							{quest.name}
						</Flex>
						{extraCSS.includes('quest-listing-in-progress') ? renderPin() : ''}
					</Flex>
				</Flex>
			);
		}

		return (
			<Accordion
				ref={scrollToPinned}
				allowToggle
				defaultIndex={isPinned ? 0 : undefined}
				variant='default'
				fontSize='14px'
			>
				<AccordionItem>
					<AccordionButton
						position='relative'
						className={`quest-step ${extraCSS}`}
						background='transparent'
						border='none'
						margin='0'
						cursor='pointer'
						justifyContent='space-between'
						textAlign='left'
						onClick={(e) => {
							clickShare(e);
							selectQuest({ quest: quest.id, step: 0 });
						}}
					>
						{totalProgress > 0 && totalProgress < 1 && (
							<ProgressBar
								position='absolute'
								top='0'
								left='0'
								theme='quest-list'
								value={totalProgress}
								max={1}
								height='100%'
								width='100%'
							/>
						)}
						<Flex position='relative' alignItems='center'>
							{quest.questIcon && <Image width='24px' height='24px' src={quest.questIcon} />}
							{quest.name}
						</Flex>
						<Flex alignItems='center'>
							<AccordionIcon />
							{extraCSS.includes('quest-listing-in-progress') ? renderPin() : ''}
						</Flex>
					</AccordionButton>
					<AccordionPanel margin='0 10px 0 20px' padding='0'>
						{renderQuestSteps}
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		);
	}

	return renderQuest(quest);
}
