import { Box, Flex, Grid, Image, Progress, Text } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useState } from 'react';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { IFarmingPlant, IFarmingPos } from '../../../../../../game-server/src/modules/skills/farming/Farming.interface';
import { usePlayerField } from '../../../../hooks/hooks';
import {
	getBasePlantCost,
	getOccupiedTiles,
	getPlantSpotsInArea,
	getPlantXP,
	getSeedSize,
	isMystSeed,
	normalizeFarmingSelection,
} from '../../../../utils/farmingFunctions';
import { getTimeString } from '../../../../helper/helperFunctions';
import { itemList } from '../../../../utils/itemList';
import { HiSparkles } from 'react-icons/hi';
import useIsMobile from '../../../../hooks/useIsMobile';

function FarmingGrid(props: {
	selectedSeed: IItem | null;
	resetSelectedSeed: () => void;
	selectedArea: { start: IFarmingPos; end: IFarmingPos } | null;
	setSelectedArea: (start?: IFarmingPos, end?: IFarmingPos) => void;
	cellSize: number;
	borderSize: number;
}) {
	const farming = usePlayerField('farming');
	const [areaPreviewStart, setAreaPreviewStart] = useState<IFarmingPos | undefined>(undefined);
	const [areaPreviewEnd, setAreaPreviewEnd] = useState<IFarmingPos | undefined>(undefined);

	const isMobile = useIsMobile();

	const occupiedTiles = getOccupiedTiles(farming);

	const selectedSeedData = props.selectedSeed ? itemList[props.selectedSeed.itemID] : null;

	function handlePointerDown(
		event: React.PointerEvent<HTMLDivElement>,
		row: number | string,
		column: number | string
	) {
		// release ponter capture if it was captured
		if (event.target instanceof Element) {
			event.target.releasePointerCapture(event.pointerId);
		}
		if (event.button === 2) {
			cancelSelection(event);
			return;
		}
		if (event.button === 0) {
			if (typeof row === 'string') row = parseInt(row);
			if (typeof column === 'string') column = parseInt(column);
			setAreaPreviewStart({ row, column });
			setAreaPreviewEnd({ row, column });
		}
	}

	function handlePointerEnter(row: number | string, column: number | string) {
		if (areaPreviewStart) {
			if (typeof row === 'string') row = parseInt(row);
			if (typeof column === 'string') column = parseInt(column);
			setAreaPreviewEnd({ row, column });
		}
	}

	function handlePointerUp(event: React.PointerEvent<HTMLDivElement>) {
		if (event.button === 2) return;
		if (areaPreviewStart && areaPreviewEnd) {
			props.setSelectedArea(areaPreviewStart, areaPreviewEnd);
		}
		setAreaPreviewStart(undefined);
		setAreaPreviewEnd(undefined);
	}

	function handleKeyUp(event: React.KeyboardEvent) {
		if (event.key === 'Escape') {
			cancelSelection(event);
		}
	}

	function cancelSelection(event: React.BaseSyntheticEvent) {
		event.preventDefault();
		props.resetSelectedSeed();
		setAreaPreviewStart(undefined);
		setAreaPreviewEnd(undefined);
		props.setSelectedArea();
	}

	function plotSize() {
		return {
			width: farming.width * props.cellSize + 2 * props.borderSize + 'px',
			height: farming.height * props.cellSize + 2 * props.borderSize + 'px',
			border: props.borderSize + 'px solid transparent',
		};
	}

	function getSeedImage(seedId: number, height: number, width: number) {
		let image = itemList[seedId].farmingStats?.image;
		if (isMystSeed(seedId)) {
			image = image?.replace('.png', '_' + width + height + '.png');
		}
		return image;
	}

	function renderBackgroundLayer() {
		return (
			<Box
				{...plotSize()}
				style={{
					borderImageSource: "url('/images/farming/farm_plot_9_slice.png')",
					borderImageSlice: '352 fill',
					borderImageWidth: 2 * props.borderSize + 'px',
					borderImageOutset: props.borderSize + 'px',
					borderImageRepeat: 'round',
				}}
			/>
		);
	}

	/**
	 * Needed to have the plant tooltip and the pointer events needed for the selection work together
	 */
	function renderPlantSubGrid(row: number, column: number, height: number, width: number) {
		const cells: React.ReactElement[] = [];
		for (let i = 0; i < height; i++) {
			for (let j = 0; j < width; j++) {
				const cellRow = row + i;
				const cellColumn = column + j;
				cells.push(
					<Box
						key={cellRow + ',' + cellColumn}
						onPointerDown={(e) => handlePointerDown(e, cellRow, cellColumn)}
						onPointerEnter={() => handlePointerEnter(cellRow, cellColumn)}
						onPointerUp={handlePointerUp}
						width={props.cellSize + 'px'}
						height={props.cellSize + 'px'}
						zIndex={1}
					/>
				);
			}
		}
		return (
			<Grid
				templateColumns={`repeat(${width}, ${props.cellSize}px)`}
				templateRows={`repeat(${height}, ${props.cellSize}px)`}
				position='absolute'
				top='0'
			>
				{cells}
			</Grid>
		);
	}

	function renderPlant(row: number, column: number) {
		const plant: IFarmingPlant = farming.plot[Number(row)][Number(column)];
		const seedData = itemList[plant.seedId];
		if (!seedData.farmingStats) return null;
		const remainingTime = seedData.farmingStats.time - plant.stage;
		const remainingTimeString = getTimeString(remainingTime * 60);
		const imageSize = seedData.farmingStats.fixedSize
			? '100%'
			: Math.round(
					((seedData.farmingStats.time - remainingTime) / (seedData.farmingStats.time * 2) + 0.5) * 100
			  ) + '%';
		const align = seedData.farmingStats.align === 'center' ? { flex: 1 } : {};
		return (
			<IdlescapeWrappingTooltip
				key={row + ',' + column}
				content={
					<>
						<Box>{itemList[plant.seedId].name}</Box>
						<Box>Exp: {getPlantXP(seedData, plant.height, plant.width)}</Box>
						<Box>Auto harvest: {plant.autoHarvest ? 'Yes' : 'No'}</Box>
						<Box>Auto plant: {plant.autoPlant ? 'Yes' : 'No'}</Box>
						<Box>Auto fertilize: {plant.autoFertilize ? 'Yes' : 'No'}</Box>
						<Box>Fertilized: {plant.fertilized ? 'Yes' : 'No'}</Box>
						{remainingTime > 0 ? (
							<Box>Remaining time: {remainingTimeString}.</Box>
						) : (
							<Box>Ready to harvest.</Box>
						)}
					</>
				}
			>
				<Flex
					key={row + ',' + column}
					flexDirection='column'
					justifyContent='end'
					alignItems='center'
					width={plant.width * props.cellSize + 'px'}
					height={plant.height * props.cellSize + 'px'}
					gridArea={
						plant.row +
						1 +
						'/' +
						(plant.column + 1) +
						'/' +
						(plant.row + plant.height + 1) +
						'/' +
						(plant.column + plant.width + 1)
					}
					position='relative'
					cursor={remainingTime > 0 ? 'default' : 'pointer'}
				>
					<Image
						src={getSeedImage(plant.seedId, plant.height, plant.width)}
						width={imageSize}
						height={imageSize}
						objectFit='contain'
						{...align}
					/>
					{renderPlantSubGrid(row, column, plant.height, plant.width)}
					{plant.fertilized && (
						<Box
							position='absolute'
							top='0'
							right='0'
							color='rgb(32, 185, 24)'
							filter='drop-shadow(0 0 0 rgb(200, 200, 200))'
						>
							<HiSparkles height='17px' width='17px' />
						</Box>
					)}
					{remainingTime > 0 ? (
						<Progress
							value={plant.stage}
							max={seedData.farmingStats.time}
							width='90%'
							colorScheme='green'
							flex='0 0 12px'
						/>
					) : (
						<Box flex='0 0 12px'>Harvest</Box>
					)}
				</Flex>
			</IdlescapeWrappingTooltip>
		);
	}

	function renderEmpty(row: number, column: number) {
		// This is for screen readers
		return (
			<Box
				key={row + ',' + column}
				width={props.cellSize + 'px'}
				height={props.cellSize + 'px'}
				gridArea={row + 1 + '/' + (column + 1) + '/' + (row + 1) + '/' + (column + 1)}
				aria-label={`Empty plot (${row}, ${column})`}
			/>
		);
	}

	function renderPlantLayer() {
		const plants: React.ReactElement[] = [];
		for (let i = 0; i < farming.height; i++) {
			for (let j = 0; j < farming.width; j++) {
				if (farming.plot[i]?.[j] !== undefined) {
					const plant = renderPlant(i, j);
					if (plant !== null) plants.push(plant);
				}
				if (!occupiedTiles[i][j]) {
					plants.push(renderEmpty(i, j));
				}
			}
		}
		return (
			<Grid
				{...plotSize()}
				templateColumns={'repeat(' + farming.width + ', 1fr)'}
				templateRows={'repeat(' + farming.height + ', 1fr)'}
				position='absolute'
				top='0'
				style={{ touchAction: 'none' }}
			>
				{plants}
			</Grid>
		);
	}

	function renderPlantPreview(seed: IItemData, row: number, column: number) {
		if (!areaPreviewStart || !areaPreviewEnd) return <></>;
		const { height: seedHeight, width: seedWidth } = getSeedSize(seed, areaPreviewStart, areaPreviewEnd);
		return (
			<Image
				key={row + ',' + column}
				src={getSeedImage(seed.id, seedHeight, seedWidth)}
				height={seedHeight * props.cellSize + 'px'}
				width={seedWidth * props.cellSize + 'px'}
				paddingBottom='12px'
				objectFit='contain'
				gridArea={row + 1 + '/' + (column + 1) + '/' + (row + seedHeight + 1) + '/' + (column + seedWidth + 1)}
				opacity='0.5'
				filter='grayscale(100%)'
			/>
		);
	}

	function renderHighlightLayer() {
		let selection: React.ReactElement = <></>;
		if (props.selectedArea) {
			selection = (
				<Box
					gridArea={
						props.selectedArea.start.row +
						1 +
						'/' +
						(props.selectedArea.start.column + 1) +
						'/' +
						(props.selectedArea.end.row + 2) +
						'/' +
						(props.selectedArea.end.column + 2)
					}
					backgroundColor='#ECCD5636'
					boxShadow='0 0 6px 3px #ECCD5660'
				/>
			);
		}
		let plants: React.ReactElement[] = [];
		let preview: React.ReactElement = <></>;
		let cost: React.ReactElement = <></>;
		if (areaPreviewStart && areaPreviewEnd) {
			const { start: previewStart, end: previewEnd } = normalizeFarmingSelection(
				areaPreviewStart,
				areaPreviewEnd
			);
			preview = (
				<Box
					gridArea={
						previewStart.row +
						1 +
						'/' +
						(previewStart.column + 1) +
						'/' +
						(previewEnd.row + 2) +
						'/' +
						(previewEnd.column + 2)
					}
					backgroundColor='#ECCD5660'
					boxShadow='0 0 3px 3px #ECCD56A8'
				/>
			);
			let seedCost = 0;
			if (props.selectedSeed && selectedSeedData) {
				const plantSpots = getPlantSpotsInArea(occupiedTiles, previewStart, previewEnd, selectedSeedData);
				plants = plantSpots.map((spot) => renderPlantPreview(selectedSeedData, spot.row, spot.column));
				const { width, height } = getSeedSize(selectedSeedData, previewStart, previewEnd);
				seedCost = getBasePlantCost(selectedSeedData, width, height) * plantSpots.length;
			}
			const selectedCells =
				(previewEnd.row - previewStart.row + 1) * (previewEnd.column - previewStart.column + 1);
			if ((!isMobile && selectedCells >= 4) || seedCost > 0) {
				cost = (
					<Flex
						gridArea={
							previewStart.row +
							1 +
							'/' +
							(previewStart.column + 1) +
							'/' +
							(previewEnd.row + 2) +
							'/' +
							(previewEnd.column + 2)
						}
						alignItems='center'
						justifyContent='center'
						textAlign='center'
						flexDirection='column'
					>
						<Text
							zIndex='1'
							textShadow='-2px 0 #000,0 2px #000,2px 0 #000,0 -2px #000,2px 0 10px #000'
							pointerEvents='none'
							maxWidth='150px'
							margin='0'
						>
							{seedCost > 1 || (!isMobile && seedCost)
								? `${seedCost} seed${seedCost === 1 ? '' : 's'} needed`
								: ''}
						</Text>
						<Text
							zIndex='1'
							textShadow='-2px 0 #000,0 2px #000,2px 0 #000,0 -2px #000,2px 0 10px #000'
							pointerEvents='none'
							maxWidth='150px'
							margin='0'
						>
							{!isMobile && selectedCells >= 4 ? 'Cancel selection with right click or ESC' : ''}
						</Text>
					</Flex>
				);
			}
		}
		return (
			<Grid
				{...plotSize()}
				templateColumns={'repeat(' + farming.width + ', 1fr)'}
				templateRows={'repeat(' + farming.height + ', 1fr)'}
				position='absolute'
				top='0'
			>
				{selection}
				{preview}
				{plants}
				{cost}
			</Grid>
		);
	}

	function renderSelectionLayer() {
		const highlights: React.ReactElement[] = [];
		for (let i = 0; i < farming.height; i++) {
			for (let j = 0; j < farming.width; j++) {
				highlights.push(
					<Box
						style={{ touchAction: 'none' }}
						key={i + ',' + j}
						onPointerDown={(e) => handlePointerDown(e, i, j)}
						onPointerEnter={() => handlePointerEnter(i, j)}
					/>
				);
			}
		}
		return (
			<Grid
				{...plotSize()}
				templateColumns={'repeat(' + farming.width + ', 1fr)'}
				templateRows={'repeat(' + farming.height + ', 1fr)'}
				position='absolute'
				top='0'
				onPointerUp={handlePointerUp}
				onKeyUp={handleKeyUp}
				tabIndex={-1}
			>
				{highlights}
			</Grid>
		);
	}

	return (
		<Box
			position='relative'
			width='fit-content'
			justifySelf='center'
			onContextMenu={(e) => cancelSelection(e)}
			gridArea='plot'
		>
			{renderBackgroundLayer()}
			{renderPlantLayer()}
			{renderHighlightLayer()}
			{renderSelectionLayer()}
		</Box>
	);
}

export default FarmingGrid;
