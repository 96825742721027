import React from 'react';
import { socket } from '../../../../services/socket.service';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import { itemImage } from '../../../../helper/itemHelperFunctions';
import { itemList } from '../../../../utils/itemList';
import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import { IdlescapeButton, IdlescapeContainer, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { cookingList } from '../../../../utils/cookingList';
import { ALCHEMY_TAGS } from '../../../../utils/constantsCollection';
import {
	getCookingDurationFormula,
	getFragmentChance,
	getItemIDFromTag,
	getLadleHaste,
	validPrepIngredient,
} from '../../../../utils/cookingFunctions';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import CookingProgressBar from './CookingProgressBar';
import { getTimeString } from '../../../../helper/helperFunctions';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { useRecoilState } from 'recoil';
import { cookingPreparationItemAtom } from '../../../../atoms/cookingPreparationItemAtom';
import { FaWalking } from 'react-icons/fa';
import ActionQueueButton from '../../ActionQueue/ActionQueueButton';
import { infernoSpeed } from '../../../../utils/smithingFunctions';

export default function Preparation() {
	const stockpile = usePlayerField('stockpile');
	const prepareItems = usePlayerField('cookingPot');
	const action = usePlayerField('actionQueue');
	const equipment = usePlayerField('equipment');
	const skills = usePlayerField('skills');
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');
	const settings = usePlayerField('settings');
	const [selectedItem, setSelectedItem] = useRecoilState(cookingPreparationItemAtom);
	const active = action?.actionType === 'Action-Cooking' && action.location === locationsIds.the_preparation_halls;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, 'cooking');
	const blitzingStrength = usePlayerEnchantmentStrength(enchantmentsIds.blitzing, 'cooking');
	const infernoStrength = usePlayerEnchantmentStrength(enchantmentsIds.inferno, 'cooking');
	const scholarStrength = usePlayerEnchantmentStrength(enchantmentsIds.scholar, 'cooking');
	const wealthStrength = usePlayerEnchantmentStrength(enchantmentsIds.wealth, 'cooking');

	const cookingLevel = skills.cooking.level + skills.cooking.masteryLevel + skillEquipmentStats.cooking;

	const ladleHaste = getLadleHaste(equipment);
	const isScholar = Boolean(scholarStrength && !wealthStrength);

	let totalTime = 0;
	let totalExp = 0;

	// Figure out total time and exp
	const filteredItems: IItem['itemID'][] = [];
	for (const itemID of prepareItems) {
		if (!validPrepIngredient(itemID)) continue;
		const item = stockpile.find((stock) => stock.itemID === itemID);
		const cookingData = cookingList[itemID];
		if (item && cookingData) {
			const amount = item.stackSize ?? 0;
			const size = Math.max(cookingData.size ?? 1, cookingData.alchemySize ?? 1);
			const difficulty = cookingData.difficulty ?? 1;
			totalTime +=
				(amount * (getCookingDurationFormula(size) / (1 + hasteStrength + ladleHaste))) /
				(1 + infernoSpeed(infernoStrength)) /
				(1 + blitzingStrength * cookingLevel);
			totalExp += difficulty * 5 * amount;
			filteredItems.push(itemID);
		}
	}

	const difficultySort = settings.cooking.sort;
	filteredItems.sort((a, b) => {
		const indexA = prepareItems.findIndex((i) => i === a);
		const indexB = prepareItems.findIndex((i) => i === b);
		if (indexA === -1 && indexB !== -1) return 1;
		if (indexA !== -1 && indexB === -1) return -1;
		if (difficultySort === 3) {
			const difA = cookingList[a].difficulty ?? 0;
			const difB = cookingList[b].difficulty ?? 0;
			if (difA - difB) return difB - difA;
		}
		if (difficultySort === 2) {
			const lvlA = cookingList[a].level ?? 0;
			const lvlB = cookingList[b].level ?? 0;
			if (lvlA - lvlB) return lvlA - lvlB;
		}
		return indexA - indexB;
	});

	function toggle() {
		if (active) {
			socket.emit('action:stop');
		} else {
			setSelectedItem(null);
			socket.emit('action:start', {
				action: 'cooking',
				location: locationsIds.the_preparation_halls,
			});
		}
	}

	function queueButton() {
		return <ActionQueueButton action='cooking' location={locationsIds.the_preparation_halls} variant='orange' />;
	}

	function itemInfo(itemID: number) {
		const cookingData = cookingList[itemID];
		if (!cookingData) {
			return (
				<Flex justifyContent='center'>
					<h2>Select an Ingredient</h2>
				</Flex>
			);
		}
		const itemData = itemList[itemID];
		const size = cookingData.size ?? 1;
		const alchemySize = cookingData.alchemySize ?? 1;

		// Difficulty and prepare chance
		const difficulty = cookingData.difficulty ?? 1;

		let experience = difficulty * 5;
		if (isScholar) {
			experience *= 1 + scholarStrength;
			totalExp *= 1 + scholarStrength;
		}
		totalExp = Math.floor(totalExp);
		experience = Math.floor(experience);

		const amount = size.toFixed(0);
		const alchemyAmount = alchemySize.toFixed(0);

		// Duration stuff
		const duration = getCookingDurationFormula(Math.max(size, alchemySize)) / (1 + hasteStrength + ladleHaste);

		// Render tags
		const tagList: React.ReactElement[] = [];
		if (cookingData.ingredientTags) {
			tagList.push(
				...cookingData.ingredientTags.map((ingredient) => {
					const itemID = getItemIDFromTag(ingredient);
					if (itemID) {
						return renderTag(itemID, ALCHEMY_TAGS.includes(itemID) ? alchemyAmount : amount, isScholar);
					}
					return <></>;
				})
			);

			// If wealth, replace everything with gold
			if (wealthStrength) {
				let goldAmount = 0;
				for (const ingredient of cookingData.ingredientTags) {
					const itemID = getItemIDFromTag(ingredient);
					if (itemID) {
						const itemData = itemList[itemID];
						if (ALCHEMY_TAGS.includes(itemID)) {
							goldAmount += (itemData.value ?? 0) * alchemySize;
						} else {
							goldAmount += (itemData.value ?? 0) * size;
						}
					}
				}
				goldAmount = Math.floor(goldAmount);
				const goldString = '~' + goldAmount.toLocaleString('en-us');
				tagList.splice(0, tagList.length);
				tagList.push(renderTag(itemsIds.gold, goldString));
			}
		}

		if (cookingData.ingredientTags?.includes('fish')) {
			tagList.push(renderTag(itemsIds.fish_oil, ((cookingData.size ?? 0) * 5).toFixed() + '%'));
		}

		function renderTag(itemID: number, amountString: string, isScholar?: boolean) {
			const itemData = itemList[itemID];
			return (
				<Box
					className='item'
					data-itemid={itemID}
					key={itemID}
					width='60px'
					height='60px'
					position='relative'
					backgroundColor='rgba(0,0,0,0.5)'
					borderRadius='10px'
					border='1px solid rgba(255, 230, 0, 0.356)'
					filter={isScholar ? 'grayscale(1)' : ''}
				>
					{itemImage(itemData)}
					<div className='centered'>{amountString}</div>
					{isScholar && (
						<Text position='relative' textAlign='center' fontSize='14px' margin='3px 0'>
							Scholar destroys food!
						</Text>
					)}
					<ItemTooltip item={{ itemID }} />
				</Box>
			);
		}

		return (
			<Flex alignItems='center' flexDirection='column' width='100%' textAlign='center'>
				<h2>
					{itemData.name}
					{itemID !== filteredItems[0] && ' (Preview)'}
				</h2>
				<Flex position='relative' gap='10px'>
					{tagList.length === 0 && 'Scholar destroys ingredients!'}
					{tagList}
				</Flex>
				<Grid
					marginTop='10px'
					gridTemplateColumns='repeat(4, 1fr)'
					alignItems='center'
					justifyItems='center'
					gap='10px'
				>
					<IdlescapeWrappingTooltip content='Average amount of ingredients prepared per action. Influenced by Inferno and Blitzing enchantments.'>
						<Box>
							<FaWalking size='25' />
						</Box>
					</IdlescapeWrappingTooltip>
					<IdlescapeWrappingTooltip content='Time per action (Total time to prepare all selected ingredient)'>
						<Image src='/images/clock.png' alt='Duration' width='25px' height='25px' />
					</IdlescapeWrappingTooltip>
					<IdlescapeWrappingTooltip content='Experience (Total experience when all selected ingredients are prepared)'>
						<Image src='/images/total_level.png' alt='Experience' width='25px' height='25px' />
					</IdlescapeWrappingTooltip>
					<IdlescapeWrappingTooltip content='Chance to find Prismatic Extract while Cooking.'>
						<Image
							src='/images/misc/keys/prismatic_extract.png'
							alt='Prismatic Extract'
							width='25px'
							height='25px'
						/>
					</IdlescapeWrappingTooltip>
					<Box margin='0'>
						{((1 + infernoSpeed(infernoStrength)) * (1 + blitzingStrength * cookingLevel)).toFixed(1)}
					</Box>
					<Box margin='0'>
						{duration.toFixed(1)}s<br />
						<Box fontSize='12px'>({getTimeString(totalTime, 2)})</Box>
					</Box>
					<Box margin='0'>
						{experience.toLocaleString('en-us')}xp
						<Box fontSize='12px'>({totalExp.toLocaleString('en-us')}xp)</Box>
					</Box>
					<Box margin='0'>{(getFragmentChance(size, difficulty, cookingLevel, true) * 100).toFixed(4)}%</Box>
				</Grid>
			</Flex>
		);
	}

	return (
		<IdlescapeContainer
			display='flex'
			flex='1 0 300px'
			alignItems='center'
			flexDirection='column'
			justifyContent='space-between'
			className='anchor-cooking-preparation'
		>
			{selectedItem ? itemInfo(selectedItem) : itemInfo(filteredItems[0])}
			<Box position='relative' bottom='0'>
				<Box height='20px'>
					<CookingProgressBar currentAction={'preparation'} />
				</Box>
				<IdlescapeButton
					onClick={toggle}
					size='medium'
					variant={filteredItems.length > 0 ? 'green' : 'disabled'}
				>
					{filteredItems.length === 0 ? 'Select an Item' : active ? 'Stop' : 'Start'}
				</IdlescapeButton>
				{queueButton()}
			</Box>
		</IdlescapeContainer>
	);
}
