// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */

export const enchantmentsIds = {
	unknown: 0,
	embers: 1,
	prospecting: 2,
	scholar: 3,
	gathering: 4,
	superheating: 5,
	pyromancy: 6,
	wealth: 7,
	haste: 8,
	naturalist: 9,
	acrobatics: 10,
	critical_strike: 11,
	poisoning: 12,
	accuracy: 13,
	deflect: 14,
	force: 15,
	healing: 16,
	weakening: 17,
	reinforcement: 18,
	protection: 19,
	parry: 20,
	false_equilibrium: 21,
	efficiency: 22,
	fishing: 23,
	cooking: 24,
	crafting: 25,
	refining: 26,
	runecrafting: 27,
	chances: 28,
	shrimp_lord: 29,
	enlightenment: 30,
	prolonging: 31,
	treasure_hunter: 32,
	inferno: 33,
	destructive_testing: 34,
	nature: 35,
	root_digging: 36,
	patience: 37,
	overhealing: 38,
	farming: 39,
	gold_digger: 40,
	fertilizing: 41,
	cultivation: 42,
	corruption: 43,
	fortitude: 44,
	aspect_of_flame: 45,
	aspect_of_lightning: 46,
	aspect_of_chaos: 47,
	fishing_magnetism: 48,
	pungent_bait: 49,
	reinforced_line: 50,
	bait_preservation: 51,
	oil_spill: 52,
	deadliest_catch: 53,
	master_chef: 54,
	herbalist: 55,
	seed_harvesting: 56,
	metallurgy: 57,
	pure_metals: 58,
	essence_concentration: 59,
	endless_ammo: 60,
	rune_reserves: 61,
	fiber_finder: 62,
	quiver: 63,
	alacrity: 64,
	hold_the_line: 65,
	taxing: 66,
	archaeology: 67,
	empowered_prospecting: 68,
	empowered_gathering: 69,
	aspect_of_ice: 70,
	soul_wisdom: 71,
	overly_well_done: 72,
	loot_goblin: 73,
	recklessness: 74,
	elemental_stabilization: 75,
	empowered_haste: 76,
	researchers_boon: 77,
	gatherers_boon: 78,
	adventurers_boon: 79,
	bag_of_holding: 80,
	engraving: 81,
	supercooling: 82,
	dwarven_engineering: 83,
	dwarven_manufacturing: 84,
	elven_logistics: 85,
	resistance: 86,
	convergence: 87,
	finesse: 88,
	crucible: 89,
	forge_enhancement: 90,
	forge_maintenance: 91,
	fibrous_fabrication: 92,
	key_hunter: 93,
	soul_trade: 94,
	deep_fry: 95,
	leftovers: 96,
	blitzing: 97,
	swift_agony: 98,
	duster: 99,
	scrapper: 100,
	immunity: 101,
	voidblessed: 102,
	quick_study: 103,
	empowered_root_digging: 104,
	elemental_accuracy: 105,
	physical_accuracy: 106,
	fishy_fortune: 107,
	fiber_snagging: 108,
	lesser_pyromancy: 109,
	junk_snagging: 110,
	crafting_talent: 500,
	dwarven_student: 501,
	elven_student: 502,
	shrimp_student: 503,
	hamster_hunter: 504,
	melter_smelter: 505,
	fresh_forager: 506,
	mining_prodigy: 507,
	foraging_prodigy: 508,
	fishing_prodigy: 509,
	mining_grandmaster: 510,
	foraging_grandmaster: 511,
	fishing_grandmaster: 512,
	bound_anger: 513,
	lucky_looter: 514,
	critical_weakpoint: 515,
	careful_criticals: 516,
	unbound_rage: 517,
	arsonist: 518,
	giants_blood: 519,
	coldblooded: 520,
	molten_metal: 521,
	slag_refinement: 522,
	seed_sifter: 523,
	goblin_slayer: 524,
	exorcist: 525,
	vigilante: 526,
	purifier: 527,
	big_game_hunter: 528,
	crusader: 529,
	elf_exterminator: 530,
	giant_slayer: 531,
	order_from_chaos: 532,
	shrimpbane: 533,
	arboreal_annihilator: 534,
	technophobe: 535,
	luddite: 536,
	greenest_thumb: 537,
	totemic_wisdom: 538,
	mysterious_harvest: 539,
	trapper: 540,
	farm_blitz: 541,
	sympathetic_growth: 542,
	crop_rotation: 543,
	tree_hugger: 544,
	heart_of_the_forest: 545,
	unadorned_agility: 546,
	featherweight_fighter: 547,
	ninelives: 548,
	featherweight_champion: 549,
	augmentation_afficionado: 550,
	research_ready: 551,
	mad_scientist: 552,
	lingering_enlightenment: 553,
	spiraling_out_of_control: 554,
	affix_affinity: 555,
	talisman_talent: 556,
	essence_concentration_expert: 557,
	silent_fury: 558,
	arrow_expenditure: 559,
	ammunition_overload: 560,
	poisoner: 561,
	druidism: 562,
	consistent_chef: 563,
	mass_crafter: 564,
	smithing_grandmaster: 565,
	smithing_legend: 566,
	cooking_grandmaster: 567,
	cooking_legend: 568,
	bulk_cooking: 569,
	pungent_runes: 570,
	overpowering_force: 571,
	overpowering_convergence: 572,
	overpowering_finesse: 573,
	messy_eater: 574,
	obsidian_forgery: 575,
	dwarven_refinement: 576,
	forest_cleaning: 577,
	wild_magic: 578,
	snowman: 579,
	fleet_fungi: 580,
	runic_racing: 581,
	gold_leaf_cook: 582,
	lesser_deflect: 583,
	sunscreen: 584,
	flowing_breath: 585,
	impenetrable_defense: 1000,
	nimble: 2000,
	demon_skin: 2001,
	intuition: 2002,
	schfftph_fzzz: 5000,
	christmas_spirit: 7000,
	easter: 7001,
	weakness: 8000,
	corrosion: 8001,
	sick: 8002,
	blind: 8003,
	stunned: 8004,
	resurrection_sickness: 8005,
	overheating: 8006,
	flame_resistance: 8007,
	soul_stealing: 8008,
	natures_sanctuary: 8009,
	regal_might: 8010,
	wide_open: 8011,
	guarded: 8012,
	taunting: 8013,
	camouflage: 8014,
	hard_to_hit: 8015,
	ice_armor: 8016,
	rooted: 8017,
	tank_taunting: 8018,
	cleanse: 8019,
	skirmishing_stance: 8020,
	sniping_stance: 8021,
	enraged: 8022,
	warded: 8023,
	pending_resurrection: 8024,
	enhanced_stun: 8025,
	enhanced_silence: 8026,
	enhanced_vulnerability: 8027,
	marked: 8028,
	transcendant_vitality: 8029,
	unbreakable_will: 8030,
	ice_prison: 8031,
	frostbite: 8032,
	winds_of_magic: 8033,
	heart_attack: 8034,
	regeneration: 9000,
	poisoned: 9001,
	danger_zone: 9500,
	calming_aura: 9501,
	master_fisherman: 10001,
	legendary_angler: 10002,
	jolly: 10003,
	phoenixs_flame: 10004,
	master_forager: 10005,
	legendary_lumberjack: 10006,
	master_miner: 10007,
	legendary_rockbreaker: 10008,
	protective_plates: 10009,
	rangers_grace: 10010,
	magical_barriers: 10011,
	dextrous_shot: 10012,
	wizards_wisdom: 10013,
	elders_elegance: 10014,
	valleys_vigor: 10015,
	nighinvincibility: 10016,
	bestial_fury: 10017,
	gargoyles_gift: 10018,
	natures_blessing: 10019,
	living_death: 10020,
	royal_reverence: 10021,
	infernal_strength: 10022,
	master_smith: 10023,
	legendary_smith: 10024,
	master_chef_set: 10025,
	legendary_chef: 10026,
	master_runecrafter: 10027,
	legendary_runecrafter: 10028,
	shrimp_gods_hunger: 10029,
	expanse_of_the_void: 10030,
	call_of_the_void: 10031,
	desolation_of_the_void: 10032,
	shrimp_gods_shell: 10033,
	shrimp_skin: 10034,
	ancient_ice_age: 10035,
	master_of_flames: 10036,
	affix: 99999999,
};
