import { Box, Img } from '@chakra-ui/react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useRecoilValue } from 'recoil';
import { screenWidthAtom } from '../../../atoms/screenWidthAtom';
import { TSkillLevelProps } from './SkillLevel';

export const SkillLevelComponent = (props: TSkillLevelProps) => {
	const screenWidth = useRecoilValue(screenWidthAtom);
	return (
		<Box
			className={`anchor-header-${props.skill}`}
			position='relative'
			display='flex'
			onClick={props.handleClick}
			cursor='pointer'
		>
			<Box width={Math.max(Math.min(screenWidth * 0.04, 50), 40)}>
				<CircularProgressbarWithChildren
					styles={buildStyles({
						trailColor: props.showMastery ? 'white' : 'gray',
						pathColor: props.showMastery ? '' : 'white',
					})}
					value={props.levelProgress}
				>
					<Img
						src={props.skillImage}
						alt={props.skill}
						height='35px'
						filter={
							props.showMastery
								? 'drop-shadow(0 0 5px #5ebeff) drop-shadow(0 0 5px rgba(94,191,255,.5))'
								: ''
						}
					/>
					{props.notification && (
						<Box
							position='absolute'
							top='0'
							right='0'
							width='50%'
							height='50%'
							backgroundColor='#ff0000'
							color='white'
							borderRadius='50%'
							display='flex'
							alignItems='center'
							justifyContent='center'
							textAlign='center'
							fontSize='0.9rem'
						>
							{props.notification}
						</Box>
					)}
				</CircularProgressbarWithChildren>
				<Box
					position='absolute'
					textShadow={props.showMastery ? '0 0 5px #5ebeff' : ''}
					fontSize={Math.max(Math.min(screenWidth * 0.018, 20), 18)}
					lineHeight='1.1em'
					textAlign='center'
					bottom='-8px'
					width='min-content'
					padding='0 3px'
					left='0'
					right='0'
					margin='0 auto'
					borderRadius='5px'
					backgroundColor='#000000BB'
				>
					{props.showMastery ? props.skillData.masteryLevel : props.skillData.level}
				</Box>
			</Box>
			{props.tooltip}
		</Box>
	);
};
