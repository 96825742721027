import React from 'react';
import ChatFunctions from './ChatFunctions';
import ChatInputField from './ChatInputField';
import ChatMessageList from './ChatMessageList';
import ChatTabsList from './ChatTabsList';
import './Chat.css';
import './ChatMessageTypes.css';
import { useRecoilValue } from 'recoil';

import { ChatPublicChannelsPopup } from './ChatPublicChannels.popup';
import { activeChannelAtom } from '../../../atoms/activeChannelAtom';
import { messageAtom } from '../../../atoms/messageAtom';
import { Box } from '@chakra-ui/react';
import { ErrorBoundary } from '../ErrorBoundary';

const ChatContainer = () => {
	const activeChannel = useRecoilValue(activeChannelAtom);

	const channels = useRecoilValue(messageAtom);
	const channelKeys = Object.keys(channels);

	const activeChannelNameOrId = channelKeys[activeChannel];

	return (
		<Box
			gridArea='chat-area'
			className={`chat-inner-container chat-container chat-container-${activeChannelNameOrId}`}
		>
			<ErrorBoundary>
				<ChatTabsList />
				<ChatMessageList />
				<ChatInputField />

				<ChatFunctions />
				<ChatPublicChannelsPopup />
			</ErrorBoundary>
		</Box>
	);
};

export default ChatContainer;
