import { Flex, FormLabel, Image, Switch, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeWrappingTooltip } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { IItem, IItemData, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { shareChestSelector } from '../../../atoms/chatHistoryAtom';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import FakeItem from '../../game/Inventory/FakeItem';

interface IChestData {
	chestID: number;
	contents: { amount: number; itemID: IItemData['id'] }[];
	amount: number;
	titleOverride?: string | undefined;
}

export default function ChestContents() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [chestData, setChestData] = useState<IChestData[]>([]);
	const [combineChests, setCombineChests] = useState(true);
	const shareChest = useSetRecoilState(shareChestSelector);

	const hasDuplicateChests = chestData.some((chest) => {
		return chestData.filter((c) => c.chestID === chest.chestID).length > 1;
	});

	useEffect(() => {
		socket.on('chest', (data) => {
			data.contents = combineItems(data.contents);
			setChestData((chestData) => [...chestData, data]);
			onOpen();
		});

		return () => {
			socket.off('chest');
		};
	}, []);

	function handleCloseModal() {
		setChestData([]);
		onClose();
	}

	function renderItems(chest: IChestData) {
		if (chest.contents.length === 0) return null;
		chest.contents.sort((a, b) => {
			if (a.itemID < b.itemID) return -1;
			if (a.itemID > b.itemID) return 1;
			return 0;
		});
		const items = chest.contents.map((loot, index) => {
			const { itemID, amount: stackSize } = loot;
			const fakeItem: TPartialItemWithItemID<IItem> = {
				itemID,
				stackSize,
			};
			return <FakeItem item={fakeItem} key={index} />;
		});
		return (
			<div className='chest-open-box all-items' key={`${chest.chestID}${chest.amount}content`}>
				{items}
			</div>
		);
	}

	function handleShareChest(chestID: IChestData['chestID']) {
		handleCloseModal();
		shareChest(chestID.toString());
	}

	function renderChests(chests: IChestData[]) {
		return chests.map((chest, index) => {
			let title;
			if (chest.titleOverride) {
				title = chest.titleOverride;
			} else {
				title = itemList[chest.chestID].name;
			}
			if (chest.amount > 1) {
				title += ' x' + chest.amount;
			}
			// @ts-ignore - findLastIndex needs ts v5.0
			const isLastChestOfType = index === chests.findLastIndex((c) => c.chestID === chest.chestID);
			return (
				<React.Fragment key={`${chest.chestID}${chest.amount}${index}title`}>
					<DialogTitle>
						{title}
						<Image
							src={itemList[chest.chestID].itemImage}
							alt={itemList[chest.chestID].name}
							className='item'
							float='unset'
							marginLeft='5px'
						/>
					</DialogTitle>
					{isLastChestOfType && (
						<IdlescapeWrappingTooltip content='Share your latest chest of this type in chat' key='share'>
							<img
								src='/images/chaticons/chat-icon-global.png'
								alt='Share'
								className='share-chest-button'
								onClick={() => handleShareChest(chest.chestID)}
							/>
						</IdlescapeWrappingTooltip>
					)}
					{renderItems(chest)}
				</React.Fragment>
			);
		});
	}

	function combineItems(contents: { itemID: number; amount: number }[]) {
		return contents.reduce((acc, item) => {
			const found = acc.find((i) => i.itemID === item.itemID);
			if (found) {
				found.amount += item.amount;
			} else {
				acc.push(item);
			}
			return acc;
		}, [] as { itemID: number; amount: number }[]);
	}

	function renderJoinedChests() {
		const joinedChests = chestData.reduce((acc, chest) => {
			if (acc[chest.chestID]) {
				acc[chest.chestID].amount += chest.amount;
				for (const item of chest.contents) {
					const index = acc[chest.chestID].contents.findIndex((c) => c.itemID === item.itemID);
					if (index >= 0) {
						acc[chest.chestID].contents[index].amount += item.amount;
					} else {
						acc[chest.chestID].contents.push(structuredClone(item));
					}
				}
			} else {
				acc[chest.chestID] = structuredClone(chest);
			}
			return acc;
		}, {} as { [key: IChestData['chestID']]: IChestData });
		return renderChests(Object.values(joinedChests));
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleCloseModal}
			className='donate-dialog feedback-dialog sell-item-dialog popup-dialog'
		>
			{hasDuplicateChests && (
				<Flex alignItems='center'>
					<FormLabel htmlFor='combine-chests-switch'>Combine drops</FormLabel>
					<Switch
						id='combine-chests-switch'
						isChecked={combineChests}
						onChange={(e) => setCombineChests(e.target.checked)}
					/>
				</Flex>
			)}
			{combineChests ? renderJoinedChests() : renderChests(chestData)}
			<DialogActions>
				<IdlescapeButton variant='red' onClick={handleCloseModal} size='medium'>
					Close
				</IdlescapeButton>
			</DialogActions>
		</Dialog>
	);
}
