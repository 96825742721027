import React from 'react';
import './StatusBar.css';
import { locations } from '../../../utils/locationList';
import TagManager from 'react-gtm-module';
import { socket } from '../../../services/socket.service';
import { usePlayerField } from '../../../hooks/hooks';
import { itemList } from '../../../utils/itemList';
import { IdlescapeButton } from '@idlescape/ui';
import ActionQueueTooltip from '../../game/ActionQueue/ActionQueueTooltip';
import Timer from '../drawer/timer';
import useIsMobile from '../../../hooks/useIsMobile';
import { getMaxQueueCount } from '../../../helper/helperFunctions';

const tagManagerArgs = {
	dataLayer: {
		event: 'currentAction',
		currentAction: 'Idling',
		characterID: window.localStorage.getItem('characterID'),
	},
};

export default function StatusAction() {
	const subscription = usePlayerField('subscription');
	const isSubscribed = subscription?.active ?? false;
	const bonusActionQueueCount = usePlayerField('bonusActionQueueCount');
	const queueRunning = usePlayerField('userActionQueueStart');
	const actionQueue = usePlayerField('actionQueue');
	const settings = usePlayerField('settings');
	const userQueue = settings.miscellaneous.userActionQueue;
	const queueInfo = actionQueue?.info?.userQueue;
	const currentQueue = userQueue[queueInfo?.position ?? 0];
	const isMobile = useIsMobile();

	const queueCount = getMaxQueueCount(isSubscribed, bonusActionQueueCount);

	let message = 'Idling';
	let shortMessage = message; // for smaller screens and browser title

	if (actionQueue) {
		const { location, actionType } = actionQueue;
		shortMessage = actionType.replace('Action-', '');
		const itemID =
			actionQueue.options?.runecraftingId ??
			actionQueue.options?.scrollcraftingId ??
			actionQueue.options?.smithing?.barId;
		if (itemID) {
			shortMessage += ': ' + itemList[itemID].name;
			message = itemList[itemID].activeMessage ?? shortMessage;
		} else {
			shortMessage += ': ' + locations[location].name;
			message = locations[location].activeMessage ?? shortMessage;
		}
	}
	if (queueRunning && currentQueue && queueInfo) {
		// If a user queue is running
		shortMessage = `${queueInfo.position + 1}. ${shortMessage}`;
		message = `${queueInfo.position + 1}. ${message}`;
	}

	// Google Analytics stuff
	tagManagerArgs.dataLayer.currentAction = message;
	TagManager.dataLayer(tagManagerArgs);

	document.title = shortMessage + ' - Idlescape';

	function stopAction() {
		socket.emit('action:stop');
	}

	function renderRemaining() {
		if (!currentQueue || !queueInfo) return null;
		let actionCount = '';
		if (currentQueue.actionCount) {
			actionCount = `(${currentQueue.actionCount - queueInfo.actionCount})`;
		}
		if (currentQueue.timeToSpendHere) {
			return (
				<>
					{actionCount} (
					<Timer
						key={new Date(queueInfo.startTime).getTime()}
						time={new Date(new Date(queueInfo.startTime).getTime() + currentQueue.timeToSpendHere)}
						countdown={true}
					/>
					)
				</>
			);
		}
		return actionCount;
	}

	return (
		<div className='status-action'>
			<div className='status-name'>
				<span className='status-message'>{isMobile ? shortMessage : message}</span>
				{renderRemaining()}
			</div>
			<div className='status-buttons'>
				{actionQueue && (
					<IdlescapeButton variant='red' width='40px' height='25px' fontSize='12px' onClick={stopAction}>
						Stop
					</IdlescapeButton>
				)}
				{queueCount > 1 && settings.miscellaneous.showQueueButton && (
					<IdlescapeButton
						variant={queueRunning ? 'green' : 'orange'}
						width='40px'
						height='25px'
						fontSize='12px'
					>
						Queue
						<ActionQueueTooltip />
					</IdlescapeButton>
				)}
			</div>
		</div>
	);
}
