import {
	TEnchant,
	TFoodEnchant,
	THealingStats,
	TStats,
	TTooltipEquipmentStats,
	TTooltipStats,
} from '../Data/TooltipData';
import {
	formatBasicStat,
	formatMultiplierStat,
	formatStat,
	stringCapitalize,
	toFixedLocale,
} from '../../../../helper/StringFormatter';
import { generateAffinityDictionary } from '../Data/DamageTypes';
import React from 'react';
import { TSkillName, TSkills } from '../../../../../../game-server/src/Atypes/Skills';
import { sortDictionaryByArray } from '../../../../helper/helperFunctions';
import {
	IItem,
	TItemTags,
	TPartialItemWithItemID,
} from '../../../../../../game-server/src/modules/items/items.interface';
import AbilityList from '../../Combat/Abilities/AbilityList';
import { abilities } from '../../../../utils/abilityList';
import { itemList } from '../../../../utils/itemList';
import { IAffix } from '../../../../../../game-server/src/modules/affixes/affixes.interface';
import { TooltipLiterals } from '../Data/TooltipLiterals';
import { affixList } from '../../../../utils/affixList';
import { formatEquipmentAffixStrength } from '../../SkillPanels/Runecrafting/AffixTooltip';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { combineItemSetDescription, renderDescription, renderRequirement } from './ModernTooltipPresenter';
import { ProgressBar } from '../../../layout/UI/ProgressBar';
import { Box, Flex } from '@chakra-ui/react';
import { TRelatedSkill } from '../../../../../../game-server/src/modules/enchantment/enchantment.interface';
import { getAugmentingTrialCount } from '../../../../utils/augmentingFunctions';
import { getAffixesSlotsForItem, affixIsStarWorthy, itemIsAffixable } from '../../../../utils/affixFunctions';

// follow rainbow
export const enchantmentOrder: { [key in TRelatedSkill]: number } = {
	combat: 10, //red
	constitution: 11,
	attack: 12,
	defense: 13,
	strength: 14,
	magic: 15,
	range: 16,
	production: 20, //yellow
	farming: 21,
	enchanting: 22,
	runecrafting: 23,
	smithing: 24,
	cooking: 25,
	crafting: 26,
	gathering: 30, //green
	mining: 31,
	foraging: 32,
	fishing: 33,
	skilling: 40, //blue
	total: 41,
};

const equipmentStatsToLabelMapping = {
	...generateAffinityDictionary('offensiveDamageAffinity', '', true),
	...generateAffinityDictionary('offensiveAccuracyAffinityRating', 'ACC', true),
	...generateAffinityDictionary('defensiveDamageAffinity', '', true),
	'weaponBonus.strength': 'STR',
	'weaponBonus.intellect': 'INT',
	'weaponBonus.dexterity': 'DEX',
	'offensiveCritical.chance': 'Crit Chance',
	'offensiveCritical.damageMultiplier': 'Crit Mult',
	'armorBonus.protection': 'PROTECTION',
	'armorBonus.resistance': 'RESISTANCE',
	'armorBonus.agility': 'AGILITY',
	'armorBonus.stamina': 'STAMINA',
	'defensiveCritical.chance': 'Crit Avoidance',
	'defensiveCritical.damageMultiplier': 'Crit Reduction',
	'toolBoost.fishing': 'FISHING',
	'toolBoost.fishingBaitPower': 'BAIT',
	'toolBoost.fishingReelPower': 'REEL',
	'toolBoost.fishingRarityPower': 'BONUS',
	'toolBoost.mining': 'MINING',
	'toolBoost.foraging': 'FORAGING',
	'toolBoost.farming': 'FARMING',
	'toolBoost.cooking': 'COOKING',
	'toolBoost.smithing': 'SMITHING',
	'toolBoost.enchanting': 'ENCHANTING',
	'toolBoost.augmentLevel': 'MAX AUG',
	'toolBoost.runecrafting': 'RUNECRAFTING',
};

export type TEquipmentStats = keyof typeof equipmentStatsToLabelMapping;

const statsToLabelMapping: Record<TStats, string> = {
	'elite.monsterDifficulty': 'Monster Difficulty',
	'elite.monsterTH': 'Monster Treasure Hunter',
	'elite.locationTH': 'Location Treasure Hunter',
	'elite.scrollWaves': 'Elite Scroll Waves',
	'elite.groupSize': 'Max Group Size',
	'fishing.bait': 'Bait',
	'fishing.reel': 'Reel',
	'fishing.bonusRarity': 'Bonus Rarity',
	'fishing.fishing': 'Fishing',
	'fishing.multBait': 'Bait Multiplier',
	'fishing.multReel': 'Reel Multiplier',
	'fishing.multBonus': 'Bonus Rarity Multiplier',
	'fishing.multFishing': 'Fishing Multiplier',
	'food.tags': 'Tags',
	'food.size': 'Size',
	'food.alchemySize': 'Alchemy Size',
	'food.difficulty': 'Difficulty',
	'food.difficultyXP': 'Difficulty for XP',
	'food.cookingEnchantment': 'Cooking Enchantment',
	'food.alchemyEnchantment': 'Alchemy Enchantment',
	'seed.experience': 'Experience',
	'seed.plotSize': 'Plot Size',
	'seed.harvestTime': 'Harvest Time',
};

const equipmentStatsTypeToLabelMapping: Record<TTooltipEquipmentStats, string> = {
	attackAffinities: 'Attack Stats',
	defenseAffinities: 'Defensive Affinities',
	strengthAffinities: 'Offensive Affinities',
	defenseStats: 'Defensive Stats',
	skillStats: 'Skilling Stats',
	strengthStats: 'Damage Stats',
};

export function formatEquipmentStats(
	allStats: Partial<Record<TTooltipEquipmentStats, Record<TEquipmentStats | string, number>>> | undefined
) {
	if (!allStats) return <></>;

	const statsHtml: Partial<Record<TTooltipEquipmentStats, React.ReactElement[]>> = {};
	let hasStats = false;
	for (const [statType, statValues] of Object.entries(allStats)) {
		const statTypeHtml: React.ReactElement[] = (statsHtml[statType as TTooltipEquipmentStats] = []);
		const sortedStatValues: Record<TEquipmentStats | string, number> = sortDictionaryByArray(
			statValues,
			Object.keys(equipmentStatsToLabelMapping)
		);
		for (const [type, bonus] of Object.entries(sortedStatValues)) {
			const bonusValue = Number(bonus);
			if (bonusValue === 0) continue;

			hasStats = true;
			let htmlClass = '';
			if (type.startsWith('offensiveDamageAffinity') || type.startsWith('defensiveDamageAffinity')) {
				if (bonusValue === 1) continue;
				htmlClass = bonusValue > 1 ? 'legacy-tooltip-stat-positive' : 'legacy-tooltip-stat-negative';
			}
			const formattedBonus = formatStat(type, bonusValue);
			statTypeHtml.push(
				<span className='item-stat'>
					<span className={htmlClass}>{formattedBonus}</span>{' '}
					{equipmentStatsToLabelMapping[type as TEquipmentStats] ?? type}
				</span>
			);
		}
	}

	return (
		<>
			{wrapTooltipItem(<hr />, !hasStats)}
			{Object.entries(statsHtml).map(([statType, statValues]) => (
				<span hidden={statValues.length === 0} key={statType}>
					{wrapTooltipItem(
						<span>{equipmentStatsTypeToLabelMapping[statType as TTooltipEquipmentStats] ?? statType}</span>
					)}
					<span className={'item-stats'} key={statType}>
						{statValues.map((statValue, index) => (
							<span key={index}>{statValue}</span>
						))}
					</span>
					{halfSpace}
				</span>
			))}
		</>
	);
}

export function formatStats(allStats: Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined) {
	if (!allStats) return;

	const statsHtml: React.ReactElement[] = [];
	for (const statValues of Object.values(allStats)) {
		for (const [type, bonus] of Object.entries(statValues)) {
			let formattedBonus = bonus;
			if (type === 'elite.monsterTH' || type === 'elite.locationTH') {
				formattedBonus = formatBasicStat(Number(bonus));
			}
			statsHtml.push(
				<span key={type}>
					{`${statsToLabelMapping[type as TStats] ?? type}: ${formattedBonus}`}
					<br />
				</span>
			);
		}
	}
	return statsHtml;
}

export function formatItemSets(itemSets: Record<string, string | number>[], compactVersion: boolean) {
	if (compactVersion || itemSets.length === 0) return <></>;
	let previousSet = '';
	let previousRequired = '';
	let previousDescription = '';
	let match = false;
	const setsInfo: React.ReactElement[] = [];
	for (const itemSet of itemSets) {
		let defaultDescription = '';
		for (const idx in enchantmentsList) {
			if (enchantmentsList[idx].name === itemSet.name) {
				defaultDescription = enchantmentsList[idx].getTooltip(0, 0);
			}
		}
		if (previousSet !== itemSet.name) {
			previousDescription = String(itemSet.description);
			previousRequired = String(itemSet.required);
			match = itemSet.equipped >= itemSet.required;
		} else {
			previousDescription = combineItemSetDescription(previousDescription, itemSet.description);
			previousRequired += ' | ' + itemSet.required;
			setsInfo.pop();
		}
		const set = (
			<span key={`${itemSet.name}-${itemSet.required}`}>
				{
					<span>
						{itemSet.name} ({itemSet.equipped}):{' '}
					</span>
				}
				{wrapTooltipItem(renderRequirement(Number(itemSet.equipped), previousRequired))}
				{wrapTooltipItem(
					<span className='flavor-text'>
						{renderDescription(match, previousDescription, defaultDescription)}
					</span>
				)}
			</span>
		);
		previousSet = String(itemSet.name);
		setsInfo.push(set);
	}

	return (
		<>
			{wrapTooltipItem(<hr />)}
			{setsInfo}
		</>
	);
}

export function formatSeedYield(
	seedYield: { name: string; productionMin: number; productionMax: number; chance: number }[]
) {
	if (seedYield.length === 0) return <></>;

	const yields = seedYield.map((production, index) => {
		let productionText = `${production.productionMin}-${production.productionMax}`;
		if (production.productionMin === 1 && production.productionMax === 1) productionText = '1';

		return (
			<span className='item-stat' key={index}>
				{productionText} {production.name}
				<span className='' hidden={production.chance === 1}>
					{' '}
					{formatMultiplierStat(production.chance)}%
				</span>
			</span>
		);
	});

	return (
		<span>
			{wrapTooltipItem(<hr />)}
			{wrapTooltipItem(<span className='item-stats'>{yields}</span>)}
		</span>
	);
}

export function formatEquipmentEnchant(
	enchant: Record<TEnchant, string | number> | undefined,
	enchantmentTier: number | undefined
) {
	if (!enchantmentTier && !enchant) return <></>;
	const enchantmentStrength = (enchant?.enchantStrength as number) ?? 0;
	const enchantDescription = enchant !== undefined && (
		<span>
			{wrapTooltipItem(
				<div className='item-enchant-text'>
					<b className={`enchanted-text-${enchant.enchantType}`}>
						{enchant.enchantName} {enchant.enchantStrength}
					</b>
					<br />
					<span className='enchanted-description'>{enchant.enchantDescription}</span>
				</div>
			)}
		</span>
	);

	const enchantSlots: React.ReactElement[] = [];
	for (let i = 0; i < (enchantmentTier ?? 0); i++) {
		enchantSlots.push(
			<div className='item-enchant-slot-slot' key={i}>
				{enchantmentStrength - 1 >= i && (
					<div className='item-enchant-slot-slot'>
						<img src='/images/16px/enchantment_filled.png' alt='Filled enchantment slot' />
					</div>
				)}
				<img
					className='item-enchant-slot-image'
					src='/images/16px/enchantmentslot_socket.png'
					alt='Enchantment Slot'
				/>
			</div>
		);
	}

	return wrapTooltipItem(
		<>
			{wrapTooltipItem(<hr />)}
			{enchantDescription}
			{enchantSlots.length > 0 && <div className='item-enchant-slot-container'>{enchantSlots}</div>}
		</>
	);
}

export function formatFoodEnchant(foodEnchant: Record<TFoodEnchant, string | number | boolean> | undefined) {
	if (!foodEnchant) return <></>;

	return (
		<>
			{wrapTooltipItem(<hr />)}
			{wrapTooltipItem(
				<>
					Grants {foodEnchant.stacks} stack{foodEnchant.stacks > 1 ? 's' : ''} of{' '}
					<b className={`enchanted-text-${foodEnchant.enchantType}`}>
						{foodEnchant.enchantName} {foodEnchant.enchantStrength}
					</b>{' '}
					when consumed.
				</>
			)}
			{wrapTooltipItem(<b>Buff only granted when consumed in combat.</b>, !foodEnchant.combat_only)}
			<br />
		</>
	);
}

export function formatHealing(healing: Record<THealingStats, number> | undefined) {
	if (!healing) return <></>;

	return (
		<>
			{wrapTooltipItem('')}
			{wrapTooltipItem(<>Heals {healing.healing} immediately</>)}
			{wrapTooltipItem(<>Heals {healing.healing + healing.healTick * healing.totalTicks} in total</>)}
			{wrapTooltipItem(<>Cooldown: {toFixedLocale(healing.cooldown)}s</>)}
			{wrapTooltipItem(<>Heal Per Tick: {healing.healTick} hp</>)}
			{wrapTooltipItem(<># of Heal Ticks: {healing.totalTicks}</>)}
			{wrapTooltipItem(<>Delay Between Ticks: {healing.tickDelay}s</>)}
		</>
	);
}

export function formatItemAbilities(itemAbilities: { name: string; id: number }[]) {
	if (itemAbilities.length === 0) return <></>;

	const abilityList = itemAbilities.map((ability) => abilities[ability.id]);
	return (
		<>
			{wrapTooltipItem(<hr />)}
			<span>
				<AbilityList abilities={abilityList} learnedAbilities={[]} justifyContent='space-around' />
				{halfSpace}
			</span>
		</>
	);
}

export function wrapTooltipItem(item: React.ReactNode, isHidden = false) {
	if (isHidden) return <></>;

	return (
		<span>
			{item}
			<br />
		</span>
	);
}
export const halfSpace = <div style={{ height: '0.5em' }}></div>;

export function formatJunkSection(tags: TItemTags[] | undefined) {
	if (!tags || !tags.includes('junk')) return <></>;

	return <span>{wrapTooltipItem(<span>Junk!</span>)}</span>;
}

export function formatRequirements(requiredStats: Partial<Record<TSkillName, number>>, skills: TSkills) {
	if (Object.keys(requiredStats).length === 0) return <></>;

	let fulfilledClass = 'tooltip-requirement-fulfilled';
	const requirements = Object.entries(requiredStats)
		.map(([skill, requiredLevel]) => {
			if (skills[skill as TSkillName].level < requiredLevel) fulfilledClass = 'tooltip-requirement-unfulfilled';
			return `${requiredLevel} ${stringCapitalize(skill)}`;
		})
		.join(', ');

	return (
		<span className={fulfilledClass}>
			Required: {requirements}
			<br />
		</span>
	);
}

export function formatAffixes(affixes: IAffix[], itemId: number) {
	const itemData = itemList[itemId];
	if (!itemIsAffixable(itemData)) return <></>;
	const maxSlots = getAffixesSlotsForItem(itemData);
	if (maxSlots === 0) return <></>;

	const affixSlots: React.ReactElement[] = [];
	for (let i = 0; i < maxSlots; i++) {
		const affix = affixes[i];
		const affixData = affix ? affixList[affix.path] : undefined;
		affixSlots.push(
			<div className='item-enchant-slot-slot' key={i}>
				{affixes.length - 1 >= i && (
					<Box
						className={`affix_runes ${affixData?.imageSheetCSS} ${affixData?.imageCSS}`}
						borderRadius='2px'
						width='20px'
						height='20px'
						alignSelf='center'
					/>
				)}
				<img className='item-enchant-slot-image' src='/images/16px/affixslot_socket.png' alt='Affix Slot' />
			</div>
		);
	}

	return (
		<>
			{wrapTooltipItem(<hr />)}
			{wrapTooltipItem(
				<div className='legacy-tooltip-item-affixes flavor-text'>
					{affixes.map((affix, index) => {
						const affixData = affixList[affix.path];
						return (
							<Box
								className='legacy-tooltip-item-affix flavor-text'
								key={affix.path}
								paddingBottom={index === affixes.length - 1 ? '6px' : undefined}
							>
								{TooltipLiterals.affixesLiterals[affix.path]}:{' '}
								{formatEquipmentAffixStrength(affixData, affix.value, itemId)}
								{affixIsStarWorthy(affixData, affix.value, itemData) && <span>&#10031;</span>}
							</Box>
						);
					})}
					<div className='item-enchant-slot-container'>{affixSlots}</div>
				</div>
			)}
		</>
	);
}

export function formatAugmentCounter(augmentCounter: number, item: TPartialItemWithItemID<IItem>) {
	if (augmentCounter === 0) return <></>;
	const itemData = itemList[item.itemID];
	const augmentThreshold = getAugmentingTrialCount(item as IItem, itemData);
	return (
		<>
			{wrapTooltipItem(<hr />)}
			<Flex position='relative' width='100%' height='20px' justifyContent='center' alignItems='center'>
				<ProgressBar
					position='absolute'
					height='100%'
					value={augmentCounter}
					min={0}
					max={augmentThreshold}
					theme='augment-counter'
					maxWidth='250px'
				/>
				<Box position='relative'>
					Augment Counter: {augmentCounter}/{augmentThreshold}
				</Box>
			</Flex>
		</>
	);
}
