import React from 'react';
import { useSetRecoilState } from 'recoil';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { TEssenceID } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import { shareItemSelector } from '../../../../atoms/chatHistoryAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { RUNECRAFTING_ESSENCE } from '../../../../utils/constantsCollection';
import { formatNumberToString, getItemColorFromNumber } from '../../../../helper/helperFunctions';
import { itemList } from '../../../../utils/itemList';
import Tooltip from '../../../layout/UI/Tooltip';
import './Runecrafting.css';

export default function RunecraftingEssenceDisplay(props: {
	resourceID: IItemData['id']; // essence or fiber
	gatherMethod?: string;
}) {
	const itemData = itemList[props.resourceID];
	const shareItem = useSetRecoilState(shareItemSelector);
	const stockpile = usePlayerField('stockpile');
	const essenceItem = stockpile.find((obj) => obj.itemID === props.resourceID);
	const essenceQuantity = essenceItem?.stackSize ?? 0;
	const talismans = usePlayerField('talismans');
	const isRunecrafting = RUNECRAFTING_ESSENCE.includes(props.resourceID);
	const hasTalisman = isRunecrafting ? talismans[props.resourceID as TEssenceID] : false;

	let needExact = false;
	if (essenceQuantity >= 100000) {
		needExact = true;
	}

	function shareEssence(e: React.MouseEvent) {
		if (!essenceItem) return;
		if (e.ctrlKey || e.metaKey) {
			shareItem(essenceItem.id.toString());
		}
	}
	return (
		<div className='runecrafting-essence-display' onClick={shareEssence}>
			<img
				src={itemData.itemImage}
				alt={itemData.name + ' Rune'}
				className={!isRunecrafting || hasTalisman ? 'has-talisman' : 'no-talisman'}
			/>
			<span className={'runecrafting-essence-counter ' + getItemColorFromNumber(essenceQuantity)}>
				{formatNumberToString(essenceQuantity)}
			</span>
			<Tooltip>
				<span className='runecrafting-tooltip'>
					{props.gatherMethod ? itemData.name + ' ' + props.gatherMethod : itemData.extraTooltipInfo}
					{needExact && (
						<>
							<hr />
							Exact essence: {essenceQuantity.toLocaleString('en-us')}
						</>
					)}
				</span>
			</Tooltip>
		</div>
	);
}
